import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MultipleItems = ({ data }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <section
        className="p-2 sm:pb-4 sm:px-20 font-medium bg-white"
        style={{ fontFamily: "Abhaya Libre" }}
      >
        <div className="container px-4 mx-auto">
          <div className="relative md:pt-16 2xl:py-8 bg-white z-10 rounded-xl">
            <h2 className="mb-4 font-bold font-heading text-3xl text-center md:text-5xl xl:text-5xl text-amber-950">
              Trusted by brands all over the country
            </h2>
            <Slider {...settings}>
              {data?.gallery_image.map((item, i) => (
                <div class="w-full px-3 mb-6 max-w-max mt-5" key={i}>
                  <img
                    className="block mx-auto"
                    src={item}
                    alt=""
                    style={{
                      width: "auto",
                      height: "116px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MultipleItems;
