import React from "react";
import Bannerbl7 from "../2020/images/Bangalore 2020/bl7.webp";
import Bannermy from "../2020/images/Mysore 2020/bannermy.jpg";

export default function Event2020() {
  return (
    <div>
      <h1
        class="text-center text-2xl md:text-4xl font-semibold mt-4 md:mt-10 text-red-950"
        style={{ fontFamily: "Abhaya Libre" }}
      >
        Glimpses of events - 2020
      </h1>
      <h2 class="text-center text-xl font-semibold text-red-950">
        Click on the events to see more details.
      </h2>
      <section
        class="py-10 md:py-16 bg-white overflow-hidden"
        style={{ fontFamily: "Abhaya Libre" }}
      >
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -m-9">
            <div class="w-full md:w-1/2 lg:w-1/3 p-6 md:p-10">
              <div class="group cursor-pointer">
                <div class="md:mb-6 overflow-hidden rounded-lg">
                  <a href="/bl1">
                    <img
                      class="w-full h-auto rounded-lg transform hover:scale-105 transition duration-500"
                      src={Bannerbl7}
                      alt=""
                    />
                  </a>
                </div>

                <h3 class="mt-2 mb-0 md:mb-4 text-2xl group-hover:text-gray-900 font-semibold text-center ">
                  Bengaluru-Jan-2020
                </h3>
              </div>
            </div>

            <div class="w-full md:w-1/2 lg:w-1/3 p-6 md:p-10">
              <div class="group cursor-pointer">
                <div class="md:mb-6 overflow-hidden rounded-lg">
                  <a href="/mys2">
                    <img
                      class="w-full h-auto rounded-lg transform hover:scale-105 transition duration-500"
                      src={Bannermy}
                      alt=""
                    />
                  </a>
                </div>

                <h3 class="mt-2 mb-0 md:mb-4 text-2xl group-hover:text-gray-900 font-semibold text-center ">
                  Mysuru-March-2020
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
