import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";

export const initialState = {
  loading: false,
  hasErrors: false,
  gallery: [],
  currentGallery: null,
};

export const gallery = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    getGallery: (state) => {
      state.loading = true;
    },

    getGallerySuccess: (state, { payload }) => {
      state.loading = false;
      state.gallery = payload;
    },

    getCurrentGallery: (state, { payload }) => {
      state.loading = false;
      state.currentGallery = payload;
    },
    getGalleryFailure: (state, { payload }) => {
      state.loading = false;
      state.gallery = payload;
    },
  },
});

export const {
  getGallery,
  getGallerySuccess,
  getCurrentGallery,
  getGalleryFailure,
} = gallery.actions;

export const gallerySelector = (state) => state.gallery;
export default gallery.reducer;

export const fetchAllGallery = () => async (dispatch) => {
  const key = "gallery";
  dispatch(getGallery());

  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + "/gallery");

    dispatch(getGallerySuccess(data));
  } catch ({ response }) {
    dispatch(getGalleryFailure());
    // response.data && message.error({ content: response.data.msg, key, duration: 2 });
  }
};

export const fetchOneGallery = (id) => async (dispatch) => {
  dispatch(getGallery());

  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + `/gallery/${id}`);
    dispatch(getCurrentGallery(data));
  } catch ({ response }) {
    dispatch(getGalleryFailure());
  }
};

export const createGallery = (values) => async (dispatch) => {
  const key = "gallery";
  dispatch(getGallery());

  try {
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/gallery",
      values,
      config
    );
    setTimeout(() => {}, 500);
    dispatch(fetchAllGallery());
  } catch ({ response }) {
    dispatch(getGalleryFailure());
  }
};

export const deleteGallery = (id) => async (dispatch) => {
  const key = "gallery";
  dispatch(getGallery());

  try {
    const { data } = await axios.delete(keyUri.BACKEND_URI + `/gallery/${id}`);
    dispatch(fetchAllGallery());
  } catch ({ response }) {
    dispatch(getGalleryFailure());
  }
};

export const updateGallery = (id, values) => async (dispatch) => {
  const key = "gallery";
  dispatch(getGallery());

  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/gallery/${id}`,
      values,
      config
    );
    // window.location.reload()
    dispatch(fetchAllGallery());
  } catch ({ response }) {
    dispatch(getGalleryFailure());
  }
};
