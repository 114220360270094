import { createSlice } from "@reduxjs/toolkit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { keyUri } from "../key";

export const initialState = {
  loading: false,

  hasErrors: false,
  all_register: [],
  currentenquiry: null,
};

export const register = createSlice({
  name: "register",
  initialState,
  reducers: {
    getRegister: (state) => {
      state.loading = true;
    },

    getRegisterSuccess: (state, { payload }) => {
      state.loading = false;
      state.register = payload;
    },

    getCurrentRegister: (state, { payload }) => {
      state.loading = false;
      state.currentenquiry = payload;
    },
    getRegisterFailure: (state) => {
      state.loading = false;
      // state. enquiry = payload
    },
  },
});

export const {
  getRegister,
  getRegisterSuccess,
  getCurrentRegister,
  getRegisterFailure,
} = register.actions;

export const registerSelector = (state) => state.register;

export const createRegister = (values) => async (dispatch) => {
  const key = " Register";
  dispatch(getRegister());
  // message.loading({ content: 'loading...', key })

  try {
    const data = await axios.post(keyUri.BACKEND_URI + "/register", values);

    dispatch(getRegisterSuccess(data));

    setTimeout(() => {
      toast.success(
        "Your Registration for the event is submitted successfully.we will get in touch with you",
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }, 0);

    setTimeout(() => {
      window.location.reload();
      // message.success({ content: data.msg, key, duation: 2 });
    }, 3000);
  } catch ({ response }) {
    toast.error(" You have already registered for this event!", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getRegisterFailure());
  }
};
export default register.reducer;
