import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEvent, eventSelector } from "../../api/events";
import SectionOne from "../homepage/section1";
import Section2 from "../homepage/section2";
import Section3 from "../homepage/section3";
import Section4 from "../homepage/section4";
import Section5 from "../homepage/section5";
import moment from "moment";
import RegistrationPopup from '../registerpopup'
import '../../App.css'

export default function Index() {
  const { event } = useSelector(eventSelector);
  const dispatch = useDispatch();
  const [currentEvent, setCurrEvent] = useState([]);



  useEffect(() => {
    for (let i = 0; i < event.length; i++) {
      if (event[i]?.event_start_date > moment().format()) {
        setCurrEvent([event[i]]);
        break;
      }
    }
  }, [event]);


  useEffect(() => {
    dispatch(fetchAllEvent());
  }, []);

  return (
    <div>
         <RegistrationPopup dataCurrent ={currentEvent[0]}/>
      <SectionOne />
      <div class="">
        {event.length < 1 || currentEvent?.length < 1 ? (
          <h2></h2>
        ) : (
          <>
            <Section2 />
          </>
        )}
      </div>

      <Section3 />
      <Section4 />
      <Section5 />
    </div>
  );
}
