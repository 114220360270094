import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEvent, eventSelector } from "../../api/events";
import moment from "moment";

export default function Section1() {
  const { event } = useSelector(eventSelector);
  const dispatch = useDispatch();
  const [liveEvent, setLiveEvent] = useState(null);

  useEffect(() => {
    dispatch(fetchAllEvent());
  }, []);
  useEffect(() => {
    for (let eventDate = 0; eventDate <= event.length; eventDate++) {
      if (
        moment(event[eventDate]?.event_start_date)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format() >
        moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format()
      ) {
        setLiveEvent([event[eventDate]]);
        break;
      }
    }
  }, [event]);


  return (
    <div>
      <section>
        <img
          className="bottom-0 left-0 w-full h-full object-cover hidden md:block"
          // style={{ height: "780px" }}
          src={liveEvent?.length > 0 && liveEvent[0]?.event_image}
        />
      </section>

      <section>
        <img
          className="bottom-0 left-0 w-full h-full object-cover block md:hidden p-3"
          src={liveEvent?.length > 0 && liveEvent[0]?.mobile_image}
        />
      </section>
    </div>
  );
}
