import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllGallery, gallerySelector } from "../api/gallery";

export default function Gallery() {
  const { gallery } = useSelector(gallerySelector);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchAllGallery());
  }, []);

  return (
    <div>
      <section class="mb-5 bg-white overflow-hidden">
        <div class="container px-4 mx-auto">
          <h2 class="sm:py-3 md:py-10 font-heading font-semibold text-red-950 text-3xl sm:text-4xl text-center">
            Gallery
          </h2>
          <div class="flex flex-wrap -m-3">
            {gallery.map((item, i) => {
              return (
                <div class="w-full md:w-1/3 p-3">
                  <a class="flex justify-center md:block h-full" href="#">
                    <div class="relative h-full md:rounded-xl overflow-hidden ">
                      <img
                        class="md:h-72 w-72 md:w-full object-cover transform hover:scale-105 transition ease-in-out duration-1000 gap-20"
                        src={item?.gallery_image}
                        alt=""
                      />
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>

        <div class="container px-4 mx-auto">
          <h2 class="sm:py-3 md:py-10 font-heading font-semibold text-red-950 text-3xl sm:text-4xl text-center">
            Videos{" "}
          </h2>
          <div class="hidden md:block">
            <div class="flex flex-wrap -m-3">
              {gallery.map((item, i) => {
                return (
                  <div class="w-full md:w-1/3 p-3">
                    <a class="flex justify-center md:block h-full" href="#">
                      <div class="relative h-full md:rounded-xl overflow-hidden ">
                        <iframe
                          width="560"
                          height="296"
                          src={item?.link_video}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>

          <div class="flex flex-wrap -m-3 block md:hidden ">
            {gallery.map((item, i) => {
              return (
                <div class="w-full md:w-1/3 p-3">
                  <a class="flex justify-center md:block h-full" href="#">
                    <div class="relative h-full md:rounded-xl overflow-hidden ">
                      <iframe
                        width="320"
                        height="200"
                        src={item?.link_video}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}
