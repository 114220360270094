import React from 'react'
import {  Navigate, Route, Routes } from 'react-router-dom'
import Appwrap from '../src/components/global/appwrap'
import Homepage from '../src/components/homepage/index'
import Upcomingshow from '../src/components/upcomingshow/index'
import Showpage from '../src/components/showpage/index'
import Gallery from '../src/components/gallery'
import Blogs from '../src/components/blogs'
import Blogview from '../src/components/blogview'
import Contact from '../src/components/contact'
import Page2018 from '../src/components/previoushow/2018/2018'
import Page2019 from '../src/components/previoushow/2019/2019'
import Event2020 from '../src/components/previoushow/2020/2020'
import Events2021 from '../src/components/previoushow/2021/2021'
import Page2023 from '../src/components/previoushow/2023/2023'
import Page2024 from '../src/components/previoushow/2024/2024'



import Ckm from '../src/components/previoushow/2018/ckm';
import Mys from "../src/components/previoushow/2018/mysore";
import Hub from "../src/components/previoushow/2018/hubli";
import Mang from "../src/components/previoushow/2018/manglore";

import Ballary from "../src/components/previoushow/2019/ballary";
import Dvg from "../src/components/previoushow/2019/dvg";
import Mys1 from "../src/components/previoushow/2019/mysore1";
import Tumk1 from "../src/components/previoushow/2019/tumkur";
import Hubbli1 from "./components/previoushow/2019/hubbli";

import Bl1 from "../src/components/previoushow/2020/banglore";
import Mys2 from '../src/components/previoushow/2020/mysore2'

import Blroct from '../src/components/previoushow/2021/banglore1'
import Blr from '../src/components/previoushow/2021/banglore2'
import Mys3 from '../src/components/previoushow/2021/mysore21'

import Event from './components/previoushow/2023/event'

import { FloatingWhatsApp, } from "react-floating-whatsapp";
import ScrollToTop from "react-scroll-to-top";

import Show from './components/show'

import Jslogo from '../src/images/jslogo.png'




export default function App() {
  return (
    <div>
        <FloatingWhatsApp
        phoneNumber="+6366771564"
        accountName="Jewellery Show"
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar={Jslogo}
      />
      <div>
        <ScrollToTop smooth className="mb-16 pl-2" color="brown" />
      </div>

      <Appwrap>
      <Routes>

      <Route exact path="/" element={<Homepage/>}/>
      <Route exact path="/upcomingshow" element={<Upcomingshow/>}/>
      <Route exact path="/showpage/:id" element={<Showpage/>}/>
      <Route exact path="/gallery" element={<Gallery/>}/>
      <Route exact path="/blogs" element={<Blogs/>}/>
      <Route exact path="/blogview/:id" element={<Blogview/>}/>
      <Route exact path="/contact" element={<Contact/>}/>
      {/* <Route exact path="/show" element={<Navigate to = "/upcomingshow"/>} /> */}
      <Route exact path="/show" element={<Show/>} />




          <Route exact path="/page2019" element={<Page2019 />} />
          <Route exact path="/events2021" element={<Events2021 />} />
          <Route exact path="/events2018" element={<Page2018 />} />
          <Route exact path="/event2020" element={<Event2020 />} />
          <Route exact path="/page2023" element={<Page2023 />} />
          <Route exact path="/page2024" element={<Page2024 />} />



          <Route exact path="/ckm" element={<Ckm />} />
          <Route exact path="/mys" element={<Mys />} />
          <Route exact path="/hub" element={<Hub />} />
          <Route exact path="/mang" element={<Mang />} />
          <Route exact path="/mys1" element={<Mys1 />} />
          <Route exact path="/tumk1" element={<Tumk1 />} />
          <Route exact path="/hubbli1" element={<Hubbli1 />} />
          {/* .............................................................................................. */}
          <Route exact path="/bl1" element={<Bl1 />} />
          <Route exact path="/ballary" element={<Ballary />} />
          <Route exact path="/dvg" element={<Dvg />} />
          <Route exact path="/mys2" element={<Mys2 />} />
          <Route exact path="/blroct" element={<Blroct />} />
          <Route exact path="/blr" element={<Blr/>} />
          <Route exact path="/mys3" element={<Mys3/>} />
          <Route exact path="/tumu" element={<Event/>} />

          
      </Routes>
      </Appwrap>
    </div>
  )
}

