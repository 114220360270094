import React from "react";
import Topnav from "./topnavbar";
import Navbar from "./navbar";
import Footer from "./footer";

export default function Appwrap({ children }) {
  return (
    <div className=" relative">
      <Topnav />
      <Navbar />
      {children}
      <Footer />
    </div>
  );
}
