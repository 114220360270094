import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createContactEnquiry } from "../api/contact";

export default function Contact() {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(createContactEnquiry(data));
  };

  return (
    <div>
      <section
        class="relative py-5 md:py-20 overflow-hidden"
        style={{ fontFamily: "Abhaya Libre" }}
      >
        <img
          class="absolute top-0 right-0 -mt-16"
          src="saturn-assets/images/contact/light-orange-right.png"
          alt=""
        />
        <div class="relative container px-4 mx-auto">
          <div class="max-w-7xl mx-auto">
            <div class="max-w-2xl mb-4 md:mb-10">
              <span class="inline-block py-1 px-3 mb-4 text-lg font-semibold text-amber-950 bg-orange-50 rounded-full">
                CONTACT US
              </span>
              <h1 class="max-w-md font-heading text-4xl xs:text-6xl font-bold text-amber-950 sm:mb-2 md:mb-4">
                <span class="font-semibold ">Feel free to reach us @</span>
              </h1>
            </div>
            <div class="flex flex-wrap -mx-4 items-center">
              <div class="w-full lg:w-1/2 px-4 mb-10 md:mb-20 lg:mb-0">
                <div class="flex mb-6 md:mb-16 items-center">
                  <div class="flex flex-shrink-0 mr-5 sm:mr-8 items-center justify-center p-1 w-10 sm:w-12 h-10 sm:h-12 rounded-full bg-amber-950">
                    <svg
                      width="30px"
                      height="30px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 9.00005L10.2 13.65C11.2667 14.45 12.7333 14.45 13.8 13.65L20 9"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3 9.17681C3 8.45047 3.39378 7.78123 4.02871 7.42849L11.0287 3.5396C11.6328 3.20402 12.3672 3.20402 12.9713 3.5396L19.9713 7.42849C20.6062 7.78123 21 8.45047 21 9.17681V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V9.17681Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                  <div>
                    <span class="text-xl sm:text-lg text-gray-500">Email</span>
                    <span class="block text-lg sm:text-2xl font-semibold text-gray-900">
                      info@thejewelleryshow.in
                    </span>
                  </div>
                </div>
                <div class="flex items-center">
                  <div class="flex flex-shrink-0 mr-5 sm:mr-8 items-center justify-center p-1 w-10 sm:w-12 h-10 sm:h-12 rounded-full bg-amber-950">
                    <svg
                      fill="white"
                      width="30px"
                      height="30px"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.748 5.773S11.418 5 10.914 5c-.496 0-.754.229-.926.387S6.938 7.91 6.938 7.91s-.837.731-.773 2.106c.054 1.375.323 3.332 1.719 6.058 1.386 2.72 4.855 6.876 7.047 8.337 0 0 2.031 1.558 3.921 2.191.549.173 1.647.398 1.903.398.26 0 .719 0 1.246-.385.536-.389 3.543-2.807 3.543-2.807s.736-.665-.119-1.438c-.859-.773-3.467-2.492-4.025-2.944-.559-.459-1.355-.257-1.699.054-.343.313-.956.828-1.031.893-.112.086-.419.365-.763.226-.438-.173-2.234-1.148-3.899-3.426-1.655-2.276-1.837-3.02-2.084-3.824a.56.56 0 0 1 .225-.657c.248-.172 1.161-.933 1.161-.933s.591-.583.344-1.27-1.906-4.716-1.906-4.716z" />
                    </svg>
                  </div>
                  <div>
                    <span class="text-xl sm:text-lg text-gray-500">Phone</span>
                    <span class="block text-lg sm:text-2xl font-semibold text-gray-900">
                      +91 6366771564
                    </span>
                  </div>
                </div>

                <div class="flex mt-6 md:mt-14 items-center">
                  <div class="flex flex-shrink-0 mr-5 sm:mr-8 items-center justify-center p-1 w-10 sm:w-12 h-10 sm:h-12 rounded-full bg-amber-950">
                    <svg
                      fill="white"
                      width="40px"
                      height="40px"
                      viewBox="0 0 100 100"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M49,18.92A23.74,23.74,0,0,0,25.27,42.77c0,16.48,17,31.59,22.23,35.59a2.45,2.45,0,0,0,3.12,0c5.24-4.12,22.1-19.11,22.1-35.59A23.74,23.74,0,0,0,49,18.92Zm0,33.71a10,10,0,1,1,10-10A10,10,0,0,1,49,52.63Z" />
                    </svg>
                  </div>
                  <div>
                    <span class="text-xl sm:text-lg text-gray-500">
                      Address
                    </span>
                    <span class="block text-lg sm:text-xl font-semibold text-gray-900">
                      31, 1st Cross, C Block, RHCS Layout,
                      <br /> Annapoorneshwari Nagara, Nagarbhavi 2nd Stage,
                      <br /> Bengaluru, 560091
                    </span>
                  </div>
                </div>
              </div>

              <div class="w-full lg:w-1/2 px-4">
                <div class="max-w-lg lg:max-w-xl lg:ml-auto">
                  <h4 class="text-3xl font-bold text-amber-950 mb-8">
                    Register to get free pass
                  </h4>
                  <form form="form" onSubmit={handleSubmit(onSubmit)}>
                    <div class="flex flex-wrap -mx-4 mb-6">
                      <div class="w-full lg:w-1/2 px-4 mb-6 lg:mb-0">
                        <div>
                          <label
                            class="block mb-1.5 text-sm font-semibold"
                            for=""
                          >
                            <span class="text-xl sm:text-lg">Full Name</span>
                            <span class="text-red-600">*</span>
                          </label>
                          <input
                            {...register("name")}
                            class="w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 focus:border-purple-500 focus:outline-purple rounded-lg"
                            type="text"
                            placeholder="Enter your name"
                          />
                        </div>
                      </div>
                      <div class="w-full lg:w-1/2 px-4">
                        <div className="mb-6">
                          <label
                            class="block mb-1.5 text-sm font-semibold"
                            for=""
                          >
                            <span class="text-xl sm:text-lg">Email</span>
                            <span class="text-red-600">*</span>
                          </label>
                          <input
                            {...register("email")}
                            class="w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 focus:border-purple-500 focus:outline-purple rounded-lg"
                            type="text"
                            placeholder="Type your email address"
                          />
                        </div>
                      </div>

                      <div class="w-full lg:w-1/2 px-4">
                        <div className="mb-6 md:mb-0">
                          <label
                            class="block mb-1.5 text-sm font-semibold"
                            for=""
                          >
                            <span class="text-xl sm:text-lg">Phone</span>
                            <span class="text-red-600">*</span>
                          </label>
                          <input
                            {...register("phone")}
                            class="w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 focus:border-purple-500 focus:outline-purple rounded-lg"
                            type="text"
                            placeholder="Type your phone number"
                          />
                        </div>
                      </div>
                      <div class="w-full lg:w-1/2 px-4">
                        <div class="mb-0 md:mb-9">
                          <label
                            class="block mb-1.5 text-sm font-semibold"
                            for=""
                          >
                            <span class="text-xl sm:text-lg">Message</span>
                            <span class="text-red-600">*</span>
                          </label>
                          <textarea
                            {...register("message")}
                            class="w-full h-12 py-3 px-4 resize-none text-sm text-gray-900 placeholder-gray-400 border border-gray-200 focus:border-purple-500 focus:outline-purple rounded-lg"
                            type="text"
                            placeholder="Type your message"
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <button
                      class="relative group inline-block flex-shrink-0 w-full sm:w-auto py-2 px-4 text-sm font-semibold text-orange-50 bg-amber-950 rounded-md overflow-hidden"
                      type="submit"
                    >
                      <span class="relative text-lg">Submit</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
