import React from "react";

export default function Section5({ data }) {
 
  return (
    <div>
      {data?.link1_image && data?.link2_image && data?.link3_image && (
        <section class="py-4 md:py-10 bg-white overflow-hidden hidden md:block">
          <div class="container px-4 mx-auto">
            <div class="max-w-2xl text-center mx-auto mb-6 md:mb-16">
              <h2 class="text-3xl md:text-5xl font-semibold text-red-950">
                Our videos
              </h2>
            </div>
            <div class="flex flex-wrap -m-9">
              <div class="w-full md:w-1/2 lg:w-1/3 p-9">
                <div class="group cursor-pointer">
                  <div class="mb-6 overflow-hidden rounded-lg">
                    <iframe
                      width="560"
                      height="312"
                      src={data?.link1_image}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/2 lg:w-1/3 p-9">
                <div class="group cursor-pointer">
                  <div class="mb-6 overflow-hidden rounded-lg">
                    <iframe
                      width="560"
                      height="312"
                      src={data?.link2_image}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/2 lg:w-1/3 p-9">
                <div class="group cursor-pointer">
                  <div class="mb-6 overflow-hidden rounded-lg">
                    <iframe
                      width="560"
                      height="312"
                      src={data?.link3_image}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* ---------------------------------------------mobile---------------------------------------------- */}

      <div>
        {data?.link1_image && data?.link2_image && data?.link3_image && (
          <section class="py-4 md:py-10 bg-white overflow-hidden block md:hidden">
            <div class="container px-4 mx-auto">
              <div class="max-w-2xl text-center mx-auto mb-6 md:mb-16">
                <h2 class="text-3xl md:text-5xl font-semibold text-red-950">
                  Our videos
                </h2>
              </div>
              <div class=" mb-6 px-2   gap-10 justify-center">
                <div class=" w-auto md:w-auto lg:w-1/4 mb-4">
                  <a href="#">
                    <iframe
                      width="320"
                      height="200"
                      src={data?.link1_image}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>{" "}
                  </a>
                </div>
                <div class="w-auto  md:w-auto lg:w-1/4 mb-4">
                  <a href="#">
                    <iframe
                      width="320"
                      height="200"
                      src={data?.link2_image}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>{" "}
                  </a>
                </div>
                <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
                  <div class="relative mx-auto  w-full rounded-lg">
                    <iframe
                      width="320"
                      height="200"
                      src={data?.link3_image}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
}
