import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";

export const initialState = {
  loading: false,
  hasErrors: false,
  logo: [],
  currentLogo: null,
};

export const logo = createSlice({
  name: "logo",
  initialState,
  reducers: {
    getLogo: (state) => {
      state.loading = true;
    },

    getLogoSuccess: (state, { payload }) => {
      state.loading = false;
      state.logo = payload;
    },

    getCurrentLogo: (state, { payload }) => {
      state.loading = false;
      state.currentLogo = payload;
    },
    getLogoFailure: (state, { payload }) => {
      state.loading = false;
      state.logo = payload;
    },
  },
});

export const { getLogo, getLogoSuccess, getCurrentLogo, getLogoFailure } =
  logo.actions;

export const logoSelector = (state) => state.logo;
export default logo.reducer;

export const fetchAllLogo = () => async (dispatch) => {
  const key = "logo";
  dispatch(getLogo());

  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + "/logo");

    dispatch(getLogoSuccess(data));
  } catch ({ response }) {
    dispatch(getLogoFailure());
  }
};

export const fetchOneLogo = (id) => async (dispatch) => {
  dispatch(getLogo());

  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + `/logo/${id}`);
    dispatch(getCurrentLogo(data));
  } catch ({ response }) {
    dispatch(getLogoFailure());
  }
};

export const createLogo = (values) => async (dispatch) => {
  const key = "logo";
  dispatch(getLogo());

  try {
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/logo",
      values,
      config
    );
    setTimeout(() => {}, 500);
    dispatch(fetchAllLogo());
  } catch ({ response }) {
    dispatch(getLogoFailure());
  }
};

export const deleteLogo = (id) => async (dispatch) => {
  const key = "logo";
  dispatch(getLogo());

  try {
    const { data } = await axios.delete(keyUri.BACKEND_URI + `/logo/${id}`);
    dispatch(fetchAllLogo());
  } catch ({ response }) {
    dispatch(getLogoFailure());
  }
};

export const updateLogo = (id, values) => async (dispatch) => {
  const key = "logo";
  dispatch(getLogo());

  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/logo/${id}`,
      values,
      config
    );
    // window.location.reload()
    dispatch(fetchAllLogo());
  } catch ({ response }) {
    dispatch(getLogoFailure());
  }
};
