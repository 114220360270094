import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Logo from "../../images/jslogo.png";
import Fade from "react-reveal/Fade";
import Countdown, { zeroPad } from "react-countdown";
import { motion, AnimatePresence } from "framer-motion";
import { json, Link, NavLink, useParams } from "react-router-dom";
import moment from "moment";
import Typed from "react-typed";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEvent, eventSelector } from "../../api/events";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

export default function Navbar() {
  const { event } = useSelector(eventSelector);
  const dispatch = useDispatch();
  let [timer, setTimer] = useState([]);
  const [currentEvent, setCurrEvent] = useState([]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropevent, setDropevent] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchAllEvent());
  }, []);

  useEffect(() => {
    id && setIsMenuOpen(false);
  }, [id]);

  useEffect(() => {
    for (let i = 0; i < event.length; i++) {
      if (event[i]?.event_start_date > moment().format()) {
        setCurrEvent([event[i]]);
        break;
      }
    }
  }, [event]);

  return (
    <div className="sticky top-0  z-50">
      <section
        className=" bg-white  sm:px-2 sm:pl-24"
        style={{ fontFamily: "Abhaya Libre" }}
      >
        <section>
          <nav class="relative sm:py-2 md:py-6">
            <div class="container px-4 mx-auto">
              <div class="flex items-center">
                {/* <a class="inline-block text-lg font-bold" href="/">
          <img class="h-14" src={Logo} alt="" width="auto"/>
        </a> */}
                <div class="lg:hidden flex ">
                  <button
                    aria-label="Open Menu"
                    title="Open Menu"
                    onClick={() => setIsMenuOpen(true)}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewbox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 12H21"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M3 6H21"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M3 18H21"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </button>
                  {event.length < 1 || currentEvent.length < 1 ? (
                    <div
                      className="flex h-16 justify-evenly align-middle"
                      style={{ fontFamily: "Abhaya Libre" }}
                    >
                      <Fade right>
                        <div className="ml-36 ">
                          <Countdown
                            date={"2022-04-01T01:02:03"}
                            // date = {moment(eachEvent?.event_start_date).format()}

                            renderer={({ days, hours, minutes, seconds }) => {
                              return (
                                <div className="sm:flex justify-center items-center w-full h-full sm:text-5xl text-xl mt-2">
                                  <div>
                                    <div className="flex justify-center">
                                      <span
                                        className="p-1  rounded-lg  text-center text-[40px] font-bold text-red-950"
                                        style={{}}
                                      >
                                        {days} <br />{" "}
                                        <div className="text-[13px] text-center">
                                          {" "}
                                          Days{" "}
                                        </div>{" "}
                                      </span>
                                      <span
                                        className="p-1  rounded-lg  text-center text-[40px] font-bold text-red-950"
                                        style={{}}
                                      >
                                        {zeroPad(hours)}
                                        <br />{" "}
                                        <div className="text-[13px] text-center">
                                          {" "}
                                          Hours{" "}
                                        </div>{" "}
                                      </span>
                                      <span
                                        className="p-1  rounded-lg  text-center text-[40px] font-bold text-red-950"
                                        style={{}}
                                      >
                                        {minutes}
                                        <br />{" "}
                                        <div className="text-[13px] text-center">
                                          {" "}
                                          Minutes{" "}
                                        </div>{" "}
                                      </span>
                                      <span
                                        className="p-1  rounded-lg  text-center text-[40px] font-bold text-red-950"
                                        style={{}}
                                      >
                                        {seconds}
                                        <br />{" "}
                                        <div className="text-[13px] text-center">
                                          {" "}
                                          Seconds{" "}
                                        </div>{" "}
                                      </span>
                                    </div>

                                    <div></div>
                                  </div>
                                </div>
                              );
                            }}
                          />
                        </div>
                      </Fade>
                    </div>
                  ) : (
                    currentEvent?.map((eachEvent, index) => {
                      //  eachEvent?.event_start_date > moment().format()
                      //  return eachEvent?.event_start_date > moment().format() && index<1 &&

                      return (
                        <div
                          className="flex  justify-evenly align-middle bg-white"
                          style={{ fontFamily: "Abhaya Libre" }}
                        >
                          <Fade right>
                            <div className="ml-36 ">
                              <Countdown
                                // date={"2022-04-01T01:02:03"}
                                date={moment(
                                  eachEvent?.event_start_date
                                ).format()}
                                renderer={({
                                  days,
                                  hours,
                                  minutes,
                                  seconds,
                                }) => {
                                  return (
                                    <div className="sm:flex justify-center items-center w-full h-full sm:text-5xl text-xl mt-2 ">
                                      <div>
                                        <div className="flex justify-center">
                                          <span
                                            className="p-1  rounded-lg  text-center text-[40px] font-bold text-red-950"
                                            style={{}}
                                          >
                                            {days} <br />{" "}
                                            <div className="text-[13px] text-center">
                                              {" "}
                                              Days{" "}
                                            </div>{" "}
                                          </span>
                                          <span
                                            className="p-1  rounded-lg  text-center text-[40px] font-bold text-red-950"
                                            style={{}}
                                          >
                                            {zeroPad(hours)}
                                            <br />{" "}
                                            <div className="text-[13px] text-center">
                                              {" "}
                                              Hours{" "}
                                            </div>{" "}
                                          </span>
                                          <span
                                            className="p-1  rounded-lg  text-center text-[40px] font-bold text-red-950"
                                            style={{}}
                                          >
                                            {minutes}
                                            <br />{" "}
                                            <div className="text-[13px] text-center">
                                              {" "}
                                              Minutes{" "}
                                            </div>{" "}
                                          </span>
                                          <span
                                            className="p-1  rounded-lg  text-center text-[40px] font-bold text-red-950"
                                            style={{}}
                                          >
                                            {seconds}
                                            <br />{" "}
                                            <div className="text-[13px] text-center">
                                              {" "}
                                              Seconds{" "}
                                            </div>{" "}
                                          </span>
                                        </div>

                                        <div></div>
                                      </div>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                          </Fade>
                        </div>
                      );
                    })
                  )}
                </div>
                <ul class="hidden lg:flex lg:w-auto lg:space-x-12 text-gray-900 ml-80">
                  <li class="group relative">
                    <a
                      class="inline-block text-xl hover:text-amber-900 font-bold"
                      href="/"
                    >
                      Home
                    </a>
                  </li>

                  <ul class="hidden lg:flex lg:w-auto lg:space-x-12">
                    <li class="group relative">
                      <a
                        class="inline-block text-xl text-gray-900 hover:text-orange-900 font-bold"
                        href="#"
                      >
                        Previous Show
                      </a>
                      <div class="hidden group-hover:block absolute top-full left-0  max-w-max p-4 z-50">
                        <div class="-mb-2 ml-8 w-4 h-4 rounded-sm bg-white   transform rotate-45"></div>
                        <div class="w-full max-w-max bg-white border border-gray-100 rounded-xl py-2 px-2">
                          <div class="">
                          <a
                              class="block py-2 px-16 text-xl text-gray-900 hover:text-white hover:bg-amber-950 rounded-lg"
                              href="/page2024"
                            >
                              2024
                            </a>
                            <a
                              class="block py-2 px-16 text-xl text-gray-900 hover:text-white hover:bg-amber-950 rounded-lg"
                              href="/page2023"
                            >
                              2023
                            </a>
                            <a
                              class="block py-2 px-16 text-xl text-gray-900 hover:text-white hover:bg-amber-950 rounded-lg"
                              href="/page2022"
                            >
                              2022
                            </a>
                            <a
                              class="block py-2 px-16 text-xl text-gray-900 hover:text-white hover:bg-amber-950 rounded-lg"
                              href="/events2021"
                            >
                              2021
                            </a>
                            <a
                              class="block py-2 px-16 text-xl text-gray-900 hover:text-white hover:bg-amber-950 rounded-lg"
                              href="/event2020"
                            >
                              2020
                            </a>
                            <a
                              class="block py-2 px-16 text-xl text-gray-900 hover:text-white hover:bg-amber-950 rounded-lg"
                              href="/page2019"
                            >
                              2019
                            </a>
                            <a
                              class="block py-2 px-16 text-xl text-gray-900 hover:text-white hover:bg-amber-950 rounded-lg"
                              href="/events2018"
                            >
                              2018
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <Link exact to="/upcomingshow">
                    <li>
                      <a
                        class="inline-block text-xl text-gray-900 hover:text-amber-900 font-bold"
                        href="/upcomingshow"
                      >
                        Upcoming Events
                      </a>
                    </li>
                  </Link>
                  <Link exact to="/gallery">
                    <li>
                      <a
                        class="inline-block text-xl text-gray-900 hover:text-amber-900 font-bold"
                        href="/"
                      >
                        Gallery
                      </a>
                    </li>
                  </Link>

                  <Link exact to="/blogs">
                    <li>
                      <a
                        class="inline-block text-xl text-gray-900 hover:text-amber-900 font-bold"
                        href="/"
                      >
                        Blogs
                      </a>
                    </li>
                  </Link>

                  <Link exact to="/contact">
                    <li>
                      <a
                        class="inline-block text-xl text-gray-900 hover:text-amber-900 font-bold "
                        href="/contact"
                      >
                        Contact
                      </a>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </nav>
        </section>

        {/* ..................................mobile............................................ */}

        <div className="flex items-center justify-between z-20 md:hidden px-2">
          <div class="lg:hidden sticky top-0 left-0 ">
            {isMenuOpen && (
              <SidebarWrap
                key="first"
                initial={{ x: -250, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -280, opacity: 1 }}
                transition={{
                  duration: 0.36,
                  type: "tween",
                  ease: "easeInOut",
                }}
              >
                <div class="absolute  w-full  ">
                  <div
                    className="container  lg:w-4/5 block"
                    style={{ fontFamily: "Figtree" }}
                  >
                    <div>
                      <div className="flex gap-0 ">
                        <li class="dropdown inline ml-4  text-start  cursor-pointer">
                          <div class="dropdown-menu absolute hidden h-auto mt-3  z-50">
                            <ul class="block w-full bg-[#FFFF] shadow px-6 py-4 text-indigo-800 rounded"></ul>
                          </div>
                        </li>

                        <Link
                          to="/profile"
                          className="flex py-6 text-indigo-800  text-[20px] font-semibold rounded-lg shrink-0 "
                        >
                          <Link to="/">
                            <img
                              src={Logo}
                              alt="logo"
                              className=" w-28 md:hidden"
                            />
                          </Link>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <MenuWrap>
                    <nav>
                      <ul
                        class="space-y-2   text-left   text-[18px] text-orange-800"
                        style={{ fontFamily: "Figtree" }}
                      >
                        <li className="nav-item pl-2 font-bold ">
                          <NavLink
                            activeClassName="selected"
                            exact
                            to="/"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            Home{" "}
                          </NavLink>
                        </li>

                        <li className="nav-item pl-2">
                          <div>
                            <li>
                              <Link
                                id="droptoggle"
                                to="#"
                                style={{
                                  backgroundColor: dropevent
                                    ? "#f5f5f5"
                                    : "#fff",
                                  color: dropevent
                                    ? "var(--brandColor)"
                                    : "text-teal-900",
                                }}
                                onClick={() => setDropevent(!dropevent)}
                                exact
                              >
                                <b> Previous Show</b>
                                {dropevent ? (
                                  <MdKeyboardArrowUp />
                                ) : (
                                  <MdKeyboardArrowDown />
                                )}
                              </Link>
                              {dropevent && (
                                <div className="dropdown_menu">
                                  <ul>
                                  <li>
                                      <Link
                                        to="/page2024"
                                        onClick={() => setIsMenuOpen(false)}
                                      >
                                        2024
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/page2023"
                                        onClick={() => setIsMenuOpen(false)}
                                      >
                                        2023
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/"
                                        onClick={() => setIsMenuOpen(false)}
                                      >
                                        2022
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/events2021"
                                        onClick={() => setIsMenuOpen(false)}
                                      >
                                        2021
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/event2020"
                                        onClick={() => setIsMenuOpen(false)}
                                      >
                                        2020
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/page2019"
                                        onClick={() => setIsMenuOpen(false)}
                                      >
                                        2019
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/events2018"
                                        onClick={() => setIsMenuOpen(false)}
                                      >
                                        2018
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </li>
                          </div>
                        </li>

                        {/* <li className="nav-item pl-2 font-bold">
                          <NavLink
                            to="/"
                            onClick={() => setIsMenuOpen(false)}
                            activeClassName="selected"
                          >
                            Previous Show
                          </NavLink>
                        </li> */}

                        <li className="nav-item pl-2 font-bold">
                          <NavLink
                            to="/upcomingshow"
                            onClick={() => setIsMenuOpen(false)}
                            activeClassName="selected"
                          >
                            Upcoming Events
                          </NavLink>
                        </li>

                        <li className="nav-item pl-2 font-bold">
                          <NavLink
                            to="/gallery"
                            onClick={() => setIsMenuOpen(false)}
                            activeClassName="selected"
                          >
                            Gallery
                          </NavLink>
                        </li>

                        <li className="nav-item pl-2 font-bold">
                          <NavLink
                            to="/blogs"
                            onClick={() => setIsMenuOpen(false)}
                            activeClassName="selected"
                          >
                            Blogs
                          </NavLink>
                        </li>

                        <li className="nav-item pl-2 font-bold">
                          <NavLink
                            to="/contact"
                            onClick={() => setIsMenuOpen(false)}
                            activeClassName="selected"
                          >
                            Contact Us
                          </NavLink>
                        </li>
                      </ul>
                    </nav>
                  </MenuWrap>
                </div>
              </SidebarWrap>
            )}
            <div>
              <AnimatePresence>
                {isMenuOpen && (
                  <BackdropWrap
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: 0.36,
                      type: "tween",
                      ease: "easeInOut",
                    }}
                    onClick={() => setIsMenuOpen(false)}
                  />
                )}
              </AnimatePresence>
            </div>

            <div></div>
          </div>
        </div>
      </section>
    </div>
  );
}

const Wrapper = styled.section`
  li > ul {
    transform: translatex(100%) scale(0);
  }
  li:hover > ul {
    transform: translatex(101%) scale(1);
  }
  li > button svg {
    transform: rotate(-90deg);
  }
  li:hover > button svg {
    transform: rotate(-270deg);
  }

  .group:hover .group-hover\:scale-100 {
    transform: scale(1);
  }
  .group:hover .group-hover\:-rotate-180 {
    transform: rotate(180deg);
  }
  .scale-0 {
    transform: scale(0);
  }
  .min-w-32 {
    min-width: 8rem;
  }
`;

const BackdropWrap = styled(motion.div)`
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: 200vh;
  background-color: #0a0a0a05;
`;

const SidebarWrap = styled(motion.aside)`
  position: fixed;
  top: 0;
  left: 0;
  width: 75%;
  height: 100vh;
  background-color: #ffffff;
  z-index: 50;
`;

const MenuWrap = styled.div`
  .dropdown_menu {
    text-align: left;
    /* height: 167px ; */
    max-height: 167px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f5f5f5;

    svg {
      font-size: 1rem;
      margin-right: 0.2rem;
    }
  }

  #subdroptoggle {
    background-color: white;
    font-size: 0.9rem;
    font-weight: 600;
    &:hover {
      background-color: #88448a;
      color: white;
    }
  }

  ul {
    padding-bottom: 0.7px;
    background-color: #f5f5f5;
    li {
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        margin-bottom: 0.1rem;
        transition: 0.3s;

        &:hover {
          background-color: #408080;
          color: white;
        }
      }

      list-style: none;
      background-color: white;
      /* color:; */
      cursor: pointer;

      svg {
        font-size: 1.3rem;
      }

      .selected {
        background-color: #88448a;
        color: white;
        width: 100%;
        display: block;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.5rem 0.75rem 0.5rem 0.9rem;
        margin-bottom: 0.3rem;
      }
    }
  }
`;
