import React, { useEffect } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { Interweave } from "interweave";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createRegister } from "../../api/register";
import { useParams } from "react-router";
import Modal from "react-modal";
import slugify from "slugify";
import { eventSelector, fetchOneEvent } from "../../api/events";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function Section2({ data }) {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { id } = useParams();

  const {currentEvent}   = useSelector(eventSelector)
  useEffect(()=>{
    const stringWithSpaces = id.replace(/_/g, ' ').trim();
    dispatch(fetchOneEvent(stringWithSpaces))
  },[id])


  const onSubmit = (data) => {
    const sendData = {
      ...data,
      event_place: currentEvent?._id,
    };
    dispatch(createRegister(sendData));
  };

  return (
    <div>
      <section
        class="relative md:pt-20 md:pb-20 lg:pb-16 bg-amber-50"
        style={{ fontFamily: "Abhaya Libre" }}
      >
        <div>
          <div class="container px-4 mx-auto">
            <div class="w-full lg:w-1/2 mb-0 md:mb-0">
            <h2 class="mb-1 text-3xl md:text-5xl font-bold font-heading text-amber-950">{data?.event_title}</h2>

              <div class="flex flex-wrap ">
                <div class="flex items-center justify-center mb-8"></div>

                <h2 class="mb-3 text-3xl md:text-2xl font-bold font-heading text-amber-950">
                  {data?.venue_name} - {data?.event_city}
                </h2>
                <p
                  className="text-amber-900 text-lg font-medium  mb-3 md:mb-0"
                  style={{ fontFamily: "Arimo" }}
                >
                  <Interweave content={data?.description} />
                </p>
              </div>

              <div className=" ">
                <Countdown
                  date={moment(data?.event_start_date).format()}
                  renderer={({ days, hours, minutes, seconds }) => {
                    return (
                      <div className="sm:flex   w-full h-full sm:text-5xl text-lg ">
                        <div>
                          <div className="flex justify-left gap-5 mb-4">
                            <span
                              className="p-1  rounded-lg  text-left text-[40px] font-bold text-amber-950"
                              style={{}}
                            >
                              {days} <br />{" "}
                              <div className="text-[24px] text-left">
                                {" "}
                                Days{" "}
                              </div>{" "}
                            </span>
                            <span
                              className="p-1  rounded-lg  text-left text-[40px] font-bold text-amber-950"
                              style={{}}
                            >
                              {zeroPad(hours)}
                              <br />{" "}
                              <div className="text-[24px] text-left">
                                {" "}
                                Hours{" "}
                              </div>{" "}
                            </span>
                            <span
                              className="p-1  rounded-lg  text-left text-[40px] font-bold text-amber-950"
                              style={{}}
                            >
                              {minutes}
                              <br />{" "}
                              <div className="text-[24px] text-left">
                                {" "}
                                Minutes{" "}
                              </div>{" "}
                            </span>
                            <span
                              className="p-1  rounded-lg  text-left text-[40px] font-bold text-amber-950"
                              style={{}}
                            >
                              {seconds}
                              <br />{" "}
                              <div className="text-[24px] text-left">
                                {" "}
                                Seconds{" "}
                              </div>{" "}
                            </span>
                          </div>

                          <div></div>
                        </div>
                      </div>
                    );
                  }}
                />
              </div>

              <div>
                <button
                  class="block text-white  bg-orange-800  font-medium rounded-lg text-xl px-5 py-2.5 text-center"
                  onClick={openModal}
                >
                  Register Now
                </button>
                <Modal
                  isOpen={modalIsOpen}
                  // onAfterOpen={afterOpenModal}
                  onRequestClose={closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div className="p-3">
                    <button
                      class="absolute top-0 right-0 m-3 inline-block text-gray-400 hover:text-gray-800"
                      onClick={closeModal}
                    >
                      <svg
                        width="14"
                        height="14"
                        viewbox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.41 6.99994L12.71 2.70994C12.8983 2.52164 13.0041 2.26624 13.0041 1.99994C13.0041 1.73364 12.8983 1.47825 12.71 1.28994C12.5217 1.10164 12.2663 0.99585 12 0.99585C11.7337 0.99585 11.4783 1.10164 11.29 1.28994L7 5.58994L2.71 1.28994C2.5217 1.10164 2.2663 0.99585 2 0.99585C1.7337 0.99585 1.4783 1.10164 1.29 1.28994C1.1017 1.47825 0.995908 1.73364 0.995908 1.99994C0.995908 2.26624 1.1017 2.52164 1.29 2.70994L5.59 6.99994L1.29 11.2899C1.19627 11.3829 1.12188 11.4935 1.07111 11.6154C1.02034 11.7372 0.994202 11.8679 0.994202 11.9999C0.994202 12.132 1.02034 12.2627 1.07111 12.3845C1.12188 12.5064 1.19627 12.617 1.29 12.7099C1.38296 12.8037 1.49356 12.8781 1.61542 12.9288C1.73728 12.9796 1.86799 13.0057 2 13.0057C2.13201 13.0057 2.26272 12.9796 2.38458 12.9288C2.50644 12.8781 2.61704 12.8037 2.71 12.7099L7 8.40994L11.29 12.7099C11.383 12.8037 11.4936 12.8781 11.6154 12.9288C11.7373 12.9796 11.868 13.0057 12 13.0057C12.132 13.0057 12.2627 12.9796 12.3846 12.9288C12.5064 12.8781 12.617 12.8037 12.71 12.7099C12.8037 12.617 12.8781 12.5064 12.9289 12.3845C12.9797 12.2627 13.0058 12.132 13.0058 11.9999C13.0058 11.8679 12.9797 11.7372 12.9289 11.6154C12.8781 11.4935 12.8037 11.3829 12.71 11.2899L8.41 6.99994Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </button>
                    <h3 class="mb-3 text-xl font-semibold text-gray-900">
                      Register Now
                      {/* {data?.venue_name} - {data?.event_city} */}
                    </h3>
                    <form
                      form="form"
                      onSubmit={handleSubmit(onSubmit)}
                      class="space-y-6"
                      action="#"
                    >
                      <div>
                        <input
                          {...register("name")}
                          type="name"
                          name="name"
                          id="name"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                          placeholder="Name"
                          required
                        />
                      </div>
                      <div>
                        <input
                          {...register("phone")}
                          type="number"
                          name="phone"
                          id="phone"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                          placeholder="Phone number"
                          required
                        />
                      </div>
                      <div>
                        <input
                          {...register("email")}
                          type="email"
                          name="email"
                          id="email"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                          placeholder="Email"
                          required
                        />
                      </div>

                    

                      <button
                        type="submit"
                        class="w-full text-white bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </Modal>
              </div>
            </div>
          </div>

          <div class="lg:absolute lg:top-0 lg:mt-8 lg:right-36 lg:w-1/3 md:h-96">
            <img
              class="h-full w-full object-cover lg:rounded-l-xl p-4 md:p-0"
              src={data?.venue_image}
              alt=""
            />
          </div>
        </div>
      </section>
    </div>
  );
}
