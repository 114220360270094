import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from "../2019/images/Ballari 2019/bannerb.jpg";
import Ballary1 from "../2019/images/Ballari 2019/ballary1.jpg";
import Ballary2 from "../2019/images/Ballari 2019/ballary2.jpg";
import Ballary3 from "../2019/images/Ballari 2019/ballary3.jpg";
import Ballary4 from "../2019/images/Ballari 2019/ballary4.jpg";
import Ballary5 from "../2019/images/Ballari 2019/ballary5.jpg";
import Ballary6 from "../2019/images/Ballari 2019/ballary6.jpg";
import Ballary7 from "../2019/images/Ballari 2019/ballary7.jpg";
import Ballary8 from "../2019/images/Ballari 2019/ballary8.jpg";

const MultipleItems = ({ data }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
};

export default function ballary() {
  return (
    <div>
      <section
        class="relative py-4 md:py-0 md:pb-5 "
        style={{ fontFamily: "Abhaya Libre" }}
      >
        <img
          class="hidden lg:block absolute top-0 left-0 mt-24"
          src=""
          alt=""
        />
        <img
          class="hidden lg:block absolute top-0 right-0 mt-40"
          src=""
          alt=""
        />
        <div class="container px-4 mx-auto">
          <div class="h-112 ">
            <img
              class="md:p-10 w-full  object-cover object-top rounded-lg"
              src={Banner}
              alt=""
            />
          </div>
        </div>
      </section>

      {/* ..................................................................................... */}

      <section
        class="relative md:pt-20 md:pb-20 lg:pb-16 bg-amber-50"
        style={{ fontFamily: "Abhaya Libre" }}
      >
        <div>
          <div class="container px-4 mx-auto">
            <div class="w-full lg:w-1/2 mb-10 lg:mb-0">
              <div class="flex flex-wrap ">
                <h2 class="md:mb-4 text-3xl md:text-5xl font-bold font-heading text-amber-950 mt-2 md:mt-0">
                  Ballari events{" "}
                </h2>
              </div>
            </div>
          </div>

          <div class="lg:absolute lg:top-0 lg:mt-8 lg:right-36 lg:w-1/3 md:h-96 ">
            <img
              class="h-full w-full object-cover lg:rounded-l-xl p-4 md:p-0 "
              src={Ballary1}
              alt=""
            />
          </div>
        </div>
      </section>

      {/* ....................................................................................... */}

      <section>
        <div
          class="container sm:pb-10 px-4 mx-auto py-10 md:py-10 bg-amber-50"
          style={{ fontFamily: "Abhaya Libre" }}
        >
          <div class="mb-4 md:mb-0 "></div>

          <div class="flex flex-wrap -mx-3 md:mt-40">
            <div class="w-full md:w-1/2 p-3">
              <img
                class="w-full h-60 md:h-72 object-none  rounded-lg"
                src={Ballary2}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 p-3">
              <img
                class="w-full h-60 md:h-72 object-none  rounded-lg"
                src={Ballary3}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-60 md:h-72 object-cover rounded-lg"
                src={Ballary4}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-60 md:h-72 object-cover rounded-lg object-top"
                src={Ballary5}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-60 md:h-72 object-cover rounded-lg"
                src={Ballary6}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 p-3">
              <img
                class="w-full h-60 md:h-72 object-none rounded-lg"
                src={Ballary7}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 p-3">
              <img
                class="w-full h-60 md:h-72 object-none rounded-lg"
                src={Ballary8}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      {/* ................................................................................. */}

      <section class="py-4 md:py-10 bg-white overflow-hidden hidden md:block">
        <div class="container px-4 mx-auto">
          <div class="max-w-2xl text-center mx-auto mb-6 md:mb-16">
            <h2 class="text-3xl md:text-5xl font-semibold text-red-950">
              Our videos
            </h2>
          </div>
          <div class="flex flex-wrap -m-9">
            <div class="w-full md:w-1/2 lg:w-1/3 p-9">
              <div class="group cursor-pointer">
                <div class="mb-6 overflow-hidden rounded-lg">
                  <iframe
                    width="560"
                    height="312"
                    src="https://www.youtube.com/embed/mrfQ8Hu_7Jc"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 p-9">
              <div class="group cursor-pointer">
                <div class="mb-6 overflow-hidden rounded-lg">
                  <iframe
                    width="560"
                    height="312"
                    src="https://www.youtube.com/embed/Vy9Yd_z0Pg0"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 p-9">
              <div class="group cursor-pointer">
                <div class="mb-6 overflow-hidden rounded-lg">
                  <iframe
                    width="560"
                    height="312"
                    src="https://www.youtube.com/embed/BbIWrAOUNaQ"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* -------------------------------------------------------- */}

      <section class="py-4 md:py-10 bg-white overflow-hidden block md:hidden">
        <div class="container px-4 mx-auto">
          <div class="max-w-2xl text-center mx-auto mb-6 md:mb-16">
            <h2 class="text-3xl md:text-5xl font-semibold text-red-950">
              Our videos
            </h2>
          </div>
          <div class=" mb-6 px-2   gap-10 justify-center">
            <div class=" w-auto md:w-auto lg:w-1/4 mb-4">
              <a href="#">
                <iframe
                  width="320"
                  height="200"
                  src="https://www.youtube.com/embed/mrfQ8Hu_7Jc"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>{" "}
              </a>
            </div>
            <div class="w-auto  md:w-auto lg:w-1/4 mb-4">
              <a href="#">
                <iframe
                  width="320"
                  height="200"
                  src="https://www.youtube.com/embed/Vy9Yd_z0Pg0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>{" "}
              </a>
            </div>
            <div class="w-auto  md:w-auto lg:w-1/4 mb-4">
              <a href="#">
                <iframe
                  width="320"
                  height="200"
                  src="https://www.youtube.com/embed/BbIWrAOUNaQ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>{" "}
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <section class="py-10 bg-white overflow-hidden">
  <div class="container px-4 mx-auto">
  <div class="max-w-2xl text-center mx-auto mb-16">
      <h2 class="text-5xl font-semibold">Our videos</h2>
    </div>
    <div class="flex flex-wrap -m-9">
      <div class="w-full md:w-1/2 lg:w-1/3 p-9">
        <div class="group cursor-pointer">
          <div class="mb-6 overflow-hidden rounded-lg">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/mrfQ8Hu_7Jc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
       
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 p-9">
        <div class="group cursor-pointer">
          <div class="mb-6 overflow-hidden rounded-lg">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/Vy9Yd_z0Pg0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
          
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 p-9">
        <div class="group cursor-pointer">
          <div class="mb-6 overflow-hidden rounded-lg">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/BbIWrAOUNaQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>  */}
    </div>
  );
}
