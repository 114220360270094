import React from "react";
import Bannerblo from "../2021/images/banoct/blo.jpg";
import Banner2 from "../2021/images/Bangalore 2021/bannerbl1.jpg";
import Bannermys from "../2021/images/Mysore 2021/bannermys.jpg";
import { Link } from "react-router-dom";

export default function Events2021() {
  return (
    <div>
      <h1
        class="text-center text-2xl md:text-4xl font-semibold mt-4 md:mt-10 text-red-950"
        style={{ fontFamily: "Abhaya Libre" }}
      >
        Glimpses of events - 2021
      </h1>
      <h2 class="text-center text-xl font-semibold text-red-950">
        Click on the events to see more details.
      </h2>
      <section
        class="py-10 md:py-16 bg-white overflow-hidden"
        style={{ fontFamily: "Abhaya Libre" }}
      >
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -m-9">
            <div class="w-full md:w-1/2 lg:w-1/3 p-6 md:p-10">
              <div class="group cursor-pointer">
                <div class="md:mb-6 overflow-hidden rounded-lg">
                  <a href="/blroct">
                    <img
                      class="w-full h-auto rounded-lg transform hover:scale-105 transition duration-500"
                      src={Bannerblo}
                      alt=""
                    />
                  </a>
                </div>

                <h3 class="mt-2 mb-0 md:mb-4 text-2xl group-hover:text-gray-900 font-semibold text-center ">
                  Bengaluru-Oct-2021
                </h3>
              </div>
            </div>

            <div class="w-full md:w-1/2 lg:w-1/3 p-6 md:p-10">
              <div class="group cursor-pointer">
                <div class="md:mb-6 overflow-hidden rounded-lg">
                  <a href="/blr">
                    <img
                      class="w-full h-auto rounded-lg transform hover:scale-105 transition duration-500"
                      src={Banner2}
                      alt=""
                    />
                  </a>
                </div>

                <h3 class="mt-2 mb-0 md:mb-4 text-2xl group-hover:text-gray-900 font-semibold text-center ">
                  Bengaluru-Jan-2021
                </h3>
              </div>
            </div>

            <div class="w-full md:w-1/2 lg:w-1/3 p-6 md:p-10">
              <div class="group cursor-pointer">
                <div class="md:mb-6 overflow-hidden rounded-lg">
                  <a href="/mys3">
                    <img
                      class="w-full h-auto rounded-lg transform hover:scale-105 transition duration-500"
                      src={Bannermys}
                      alt=""
                    />
                  </a>
                </div>

                <h3 class="mt-2 mb-0 md:mb-4 text-2xl group-hover:text-gray-900 font-semibold text-center ">
                  Mysuru-March-2021
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}


// import React from 'react'
// import { useForm } from "react-hook-form";
// import Modal from 'react-modal';
// import { useDispatch } from "react-redux";
// import { createRegister } from "../../../api/register";
// import { useParams } from "react-router";

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//   },
// };

// export default function Events2021() {

//   let subtitle;
//   const [modalIsOpen, setIsOpen] = React.useState(false);

//   function openModal() {
//     setIsOpen(true);
//   }

//   function afterOpenModal() {
//     // references are now sync'd and can be accessed.
//     subtitle.style.color = '#f00';
//   }

//   function closeModal() {
//     setIsOpen(false);
//   }

//   const { register, handleSubmit } = useForm();
//   const dispatch = useDispatch();
//   const { id } = useParams();

//   const onSubmit = (data) => {
//     const sendData = {
//       ...data,
//       event_place: id,
//     };

//     dispatch(createRegister(sendData));
//   };

//   return (
    
//     <div>
//     <button class="block text-white  bg-orange-800  font-medium rounded-lg text-xl px-5 py-2.5 text-center"
//  onClick={openModal}>Register Now</button>
//     <Modal
//       isOpen={modalIsOpen}
//       // onAfterOpen={afterOpenModal}
//       onRequestClose={closeModal}
//       style={customStyles}
//       contentLabel="Example Modal"
//     >
//       <button onClick={closeModal}>close</button>
//       <h3 class="mb-2 text-xl font-semibold text-gray-900">sgwegwegw</h3>
//       <h3 class="mb-2 text-xl font-semibold text-gray-900">
                        
//                         {data?.venue_name} - {data?.event_city}
//                       </h3>
//       <form
//                         form="form"
//                         onSubmit={handleSubmit(onSubmit)}
//                         class="space-y-6"
//                         action="#"
//                       >
//                         <div>
//                           <input
//                             {...register("name")}
//                             type="name"
//                             name="name"
//                             id="name"
//                             class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
//                             placeholder="Name"
//                             required
//                           />
//                         </div>
//                         <div>
//                           <input
//                             {...register("phone")}
//                             type="number"
//                             name="phone"
//                             id="phone"
//                             class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
//                             placeholder="Phone number"
//                             required
//                           />
//                         </div>
//                         <div>
//                           <input
//                             {...register("email")}
//                             type="email"
//                             name="email"
//                             id="email"
//                             class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
//                             placeholder="name@company.com"
//                             required
//                           />
//                         </div>

//                         <div>
//                           <input
//                             {...register("date")}
//                             type="date"
//                             name="date"
//                             id="date"
//                             placeholder=""
//                             class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
//                             required
//                           />
//                         </div>

//                         <button
//                           type="submit"
//                           class="w-full text-white bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
//                         >
//                           Submit
//                         </button>
//                       </form>
//     </Modal>
//   </div>
    
//   )
// }
