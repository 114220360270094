import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEvent, eventSelector } from "../../api/events";
import Fade from "react-reveal/Fade";
import Countdown, { zeroPad } from "react-countdown";
import Logo from "../../images/jslogo.png";
import Typed from "react-typed";
import moment from "moment";

export default function Topnavbar() {
  const { event } = useSelector(eventSelector);
  const dispatch = useDispatch();
  const [currentEvent, setCurrentEvent] = useState(null);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchAllEvent());
  }, []);

  useEffect(() => {
    const upcomingEvent = event.find(
      (e) => e.event_start_date > moment().format()
    );
    setCurrentEvent(upcomingEvent);
  }, [event]);

  return (
    <div className="hidden md:block">
      {event.length < 1 || !currentEvent ? (
        <div
          className="flex h-28 justify-evenly align-middle bg-[#491818]"
          style={{ fontFamily: "Abhaya Libre" }}
        >
          <div className=" pr-10">
            <a href="/">
              <img class="h-20 m-4 ml-20" src={Logo} alt="" />
            </a>
          </div>

          <div
            class="text-white font-semibold mt-4 w-52"
            style={{ fontFamily: "Abhaya Libre" }}
          >
            <p class="text-md text-center">Upcoming Event</p>
            <Typed
              className="flex justify-center text-white text-xl font-semibold"
              style={{ fontFamily: "Abhaya Libre" }}
              strings={["We will be Back Soon"]}
              typeSpeed={120}
              backSpeed={140}
              loop
            />
          </div>
          <Fade right>
            <div className=" ">
              <Countdown
                date={moment().format()}
                renderer={({ days, hours, minutes, seconds }) => (
                  <div className="sm:flex justify-center items-center w-full h-full sm:text-5xl text-xl ">
                    <div>
                      <div className="flex justify-center gap-5 mb-4">
                        <span className="p-1 rounded-lg text-center text-[40px] font-bold text-white">
                          {days} <br />{" "}
                          <div className="text-[13px] text-center"> Days </div>{" "}
                        </span>
                        <span className="p-1 rounded-lg text-center text-[40px] font-bold text-white">
                          {zeroPad(hours)}
                          <br />{" "}
                          <div className="text-[13px] text-center">
                            {" "}
                            Hours{" "}
                          </div>{" "}
                        </span>
                        <span className="p-1 rounded-lg text-center text-[40px] font-bold text-white">
                          {minutes}
                          <br />{" "}
                          <div className="text-[13px] text-center">
                            {" "}
                            Minutes{" "}
                          </div>{" "}
                        </span>
                        <span className="p-1 rounded-lg text-center text-[40px] font-bold text-white">
                          {seconds}
                          <br />{" "}
                          <div className="text-[13px] text-center">
                            {" "}
                            Seconds{" "}
                          </div>{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
          </Fade>
        </div>
      ) : (
        <div
          className="flex h-28 justify-evenly align-middle bg-[#491818]"
          style={{ fontFamily: "Abhaya Libre" }}
        >
          <div className="pr-10">
            <img class="h-24 m-2 ml-20" src={Logo} alt="" />
          </div>

          <div
            class="text-white font-semibold mt-4"
            style={{ fontFamily: "Abhaya Libre" }}
          >
            <p class="text-md text-center">Upcoming Event</p>
            <p className="text-2xl font-semibold">
              {moment(currentEvent.event_start_date).format("DD")} {"- "}
              {moment(currentEvent.event_end_date).format("DD")}{" "}
              {moment(currentEvent.event_start_date).format("MMMM")}{" "}
              {moment(currentEvent.event_start_date).format("YYYY")}
            </p>
            <Typed
              className="flex justify-center text-white text-xl font-semibold"
              style={{ fontFamily: "Abhaya Libre" }}
              strings={[`${currentEvent.event_city}`]}
              typeSpeed={120}
              backSpeed={140}
              loop
            />
          </div>

          <Fade right>
            <div className=" ">
              <Countdown
                date={moment(currentEvent.event_start_date).format()}
                renderer={({ days, hours, minutes, seconds }) => (
                  <div className="sm:flex justify-center items-center w-full h-full sm:text-5xl text-xl ">
                    <div>
                      <div className="flex justify-center gap-5 mb-4">
                        <span className="p-1 rounded-lg text-center text-[40px] font-bold text-white">
                          {days} <br />{" "}
                          <div className="text-[13px] text-center"> Days </div>{" "}
                        </span>
                        <span className="p-1 rounded-lg text-center text-[40px] font-bold text-white">
                          {zeroPad(hours)}
                          <br />{" "}
                          <div className="text-[13px] text-center">
                            {" "}
                            Hours{" "}
                          </div>{" "}
                        </span>
                        <span className="p-1 rounded-lg text-center text-[40px] font-bold text-white">
                          {minutes}
                          <br />{" "}
                          <div className="text-[13px] text-center">
                            {" "}
                            Minutes{" "}
                          </div>{" "}
                        </span>
                        <span className="p-1 rounded-lg text-center text-[40px] font-bold text-white">
                          {seconds}
                          <br />{" "}
                          <div className="text-[13px] text-center">
                            {" "}
                            Seconds{" "}
                          </div>{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
          </Fade>
        </div>
      )}
    </div>
  );
}
