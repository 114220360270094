import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEvent, eventSelector } from "../../api/events";
import { Link } from "react-router-dom";
import moment from "moment";
import slugify from "slugify";

export default function Section2() {
  const { event } = useSelector(eventSelector);

  const dispatch = useDispatch();
  let [timer, setTimer] = useState([]);

  function createArray(startValue, endValue) {
    timer = [];
    for (var i = startValue; i <= endValue; i++) {
      timer.push(i);
    }
  }

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchAllEvent());
  }, []);

  return (
    <div>
      <section
        class="relative sm:py-10  bg-white overflow-x-hidden"
        style={{ fontFamily: "Abhaya Libre" }}
      >
        <div class="container mx-auto px-4">
          <h2 class="py-3 md:py-0 md:mb-4 text-4xl md:text-5xl font-bold font-heading text-center text-amber-950 ml-10 md:ml-0">
            Upcoming Events
          </h2>
          <div>
            {event.map((item, i) => {
                 const slugTitle = slugify(item?.event_title, {
                  remove: /[?]/g,
                  replacement: "_",
                });
              const timerStart = Number(
                moment(item?.event_start_date).format("DD")
              );
              const timerend = Number(
                moment(item?.event_end_date).format("DD")
              );
              createArray(timerStart, timerend);

              return (
                item?.event_start_date > moment().format() && (
                  <div class="relative flex  flex-wrap bg-amber-50">
                    <div class="hidden md:block absolute top-1/2 left-0 w-64 h-2/3 -ml-6 transform -translate-y-1/2 "></div>
                    <div class="relative w-full md:w-1/2 lg:w-1/3 h-64 md:h-auto">
                      <img
                        class="h-full w-full object-cover p-2 rounded-2xl"
                        src={item?.venue_image}
                        alt=""
                      />
                    </div>

                    <div
                      class="w-full md:w-1/2 lg:w-2/3 p-2 md:p-6 self-center"
                      style={{ fontFamily: "Arimo" }}
                    >
                      <div class="max-w-lg mx-auto">
                        <div class="mb-2 pb-2 md:pb-6 border-b border-b-amber-950">
                          <h2 class="mb-2 text-3xl md:text-4xl font-bold font-heading text-amber-950 ">
                            {item?.event_city}{" "}
                          </h2>

                          {timer.map((item, i) => {
                            return (
                              <span class="text-2xl md:text-3xl font-bold font-heading text-amber-950">
                                {item}
                                {i < 2 ? "- " : " "}
                              </span>
                            );
                          })}
                          <span class="text-2xl md:text-3xl font-bold font-heading text-amber-950">
                            {moment(item?.event_start_date).format("MMMM")}{" "}
                            {moment(item?.event_start_date).format("YYYY")}
                          </span>
                        </div>

                        <p class="mb-2 md:mb-14 text-2xl font-bold text-amber-950">
                          {item?.event_title}{" "}
                        </p>
                        <div class="flex flex-wrap items-center">
                          <div class="flex items-center justify-end">
                            <div class="text-center mb-4 md:mb-0">
                              <Link exact to={`/showpage/${slugTitle}`}>
                                <a class="inline-block bg-amber-950  text-white font-bold font-heading py-2 md:py-3 px-3 md:px-8 rounded-md uppercase">
                                  Show More
                                </a>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}
