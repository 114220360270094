import React, { useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { eventSelector, fetchAllEvent } from "../../../api/events";
import moment from "moment";
import slugify from "slugify";

export default function Page2023() {
  const dispatch = useDispatch();
  const { event } = useSelector(eventSelector);

  useEffect(() => {
    dispatch(fetchAllEvent);
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <h1
        class="text-center text-2xl md:text-4xl font-semibold mt-4 md:mt-10 text-red-950"
        style={{ fontFamily: "Abhaya Libre" }}
      >
        Glimpses of events - 2024
      </h1>
      <h2 class="text-center text-xl font-semibold text-red-950">
        Click on the events to see more details.
      </h2>

      <div class="py-5 container px-4 mx-auto hidden md:block">
          <div class="flex flex-wrap -m-3">
          {event?.map((eachEvent, i) => {
             const slugTitle = slugify(eachEvent?.event_title, {
                  remove: /[?]/g,
                  replacement: "_",
                });
            if (
              moment(eachEvent.event_start_date).year() == 2024 &&
              eachEvent?.event_start_date < moment().format()
            ) 
            {
              return (
                <div class="w-full md:w-1/3 p-3">
                  <a class="flex justify-center md:block h-full" href="#">
                    <div class="relative h-full md:rounded-xl overflow-hidden ">
                 <Link
                    to={`/showpage/${slugTitle}`}
                    class=" -mx-3 mb-8 lg:mb-6"
                  >
                    {/* <Link to='/tumu'> */}
                      <img
                        class="mt-3 md:h-64 w-72 md:w-full object-cover transform hover:scale-105 transition duration-500"
                        src={eachEvent.event_image}
                        alt=""
                      />
                      </Link>
                    </div>
                    <h3 class="text-2xl text-red-950 hover:text-red-950 font-semibold text-center">
                {eachEvent?.event_city}-
                {moment(eachEvent?.event_start_date).format("MMM")}-
                {moment(eachEvent.event_start_date).year()}
              </h3>
                  </a>
                </div>
              );
              }
            })}
          </div>
        </div>

{/* -----------------------------------------------mobile------------------------------------------------------- */}
      
      <div class="flex flex-wrap justify-center w-full h-full block md:hidden py-10">
  {event?.map((eachEvent, index) => {
   if (
      moment(eachEvent.event_start_date).year() == 2024 &&
      eachEvent?.event_start_date < moment().format()
    ) {
      return (
        <section
          class="py-2 overflow-hidden flex items-center justify-center md:w-96"
          style={{ fontFamily: "" }}
        >
          <div class="container  mx-auto">
            <div class="group cursor-pointer">
              <div class="mb-6 overflow-hidden rounded-lg">
                <Link to="/tumu">
                  <img
                    class="w-full rounded-lg transform hover:scale-105 transition duration-500"
                    src={eachEvent.event_image}
                    alt=""
                  />
                </Link>
              </div>
              <h3 class="text-2xl group-hover:text-gray-900 font-semibold text-center">
                {eachEvent?.event_city}-
                {moment(eachEvent?.event_start_date).format("MMM")}-
                {moment(eachEvent.event_start_date).year()}
              </h3>
            </div>
          </div>
        </section>
      );
    }
  })}
</div>

    </div>
  );
}
