import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllBanner, bannerSelector } from "../../api/banner";
import { fetchAllMobbanner, mobbannerSelector } from "../../api/mobilebanner";
import Slider from "react-slick";

export default function Section1() {
  const dispatch = useDispatch();
  const { banner } = useSelector(bannerSelector);
  const { mobbanner } = useSelector(mobbannerSelector);

  useEffect(() => {
    dispatch(fetchAllBanner());
  }, []);

  useEffect(() => {
    dispatch(fetchAllMobbanner());
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 1000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div class="hidden md:block">
        <Slider {...settings}>
          {banner.map((item, i) => {
            return (
              <div>
                <img
                  class=" bottom-0 left-0 w-full h-full "
                  src={item?.banner_image}
                  alt=""
                />
              </div>
            );
          })}
        </Slider>
      </div>

      <div class="block md:hidden">
        <Slider {...settings}>
          {mobbanner.map((item, i) => {
            return (
              <div>
                <img
                  class=" bottom-0 left-0 w-full h-full p-2 "
                  src={item?.mobilebanner_image}
                  alt=""
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}
