import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";

export const initialState = {
  loading: false,
  hasErrors: false,
  event: [],
  currentEvent: null,
  sectionone: false,
  sectiontwo: false,
  sectionthree: false,
  sectionfour: false,
  sectionone_data: [],
  sectiontwo_data: null,
  sectionthree_data: null,
  sectionfour_data: null,
};

export const event = createSlice({
  name: "event",
  initialState,
  reducers: {
    getSectionOne: (state) => {
      state.loading = true;
      state.one = true;
      state.sectionone = true;
      state.activeTab = "1";
    },

    getgetSectionOneactive: (state) => {
      state.loading = true;
    },

    getSectionTwo: (state) => {
      state.loading = true;
      state.one = true;
      state.two = true;
      state.sectionone = true;
      state.sectiontwo = true;
      state.activeTab = "2";
    },

    getSectionThree: (state) => {
      state.loading = true;
      state.one = true;
      state.two = true;
      state.three = true;
      state.sectionone = true;
      state.sectiontwo = true;
      state.sectionthree = true;
      state.activeTab = "3";
    },

    getSectionFour: (state) => {
      state.loading = true;
      state.sectionone = true;
      state.sectiontwo = true;
      state.sectionthree = true;
      state.sectionfour = true;
      state.activeTab = "4";
    },

    SectionOneInfo: (state, { payload }) => {
      state.loading = true;
      state.sectionone_data = payload;
    },

    SectionTwoInfo: (state, { payload }) => {
      state.loading = true;
      state.sectiontwo_data = payload;
    },

    SectionThreeInfo: (state, { payload }) => {
      state.loading = true;
      state.sectionthree_data = payload;
    },

    SectionFourInfo: (state, { payload }) => {
      state.loading = true;
      state.sectionfour_data = payload;
    },

    getEvent: (state) => {
      state.loading = true;
    },

    getEventSuccess: (state, { payload }) => {
      state.loading = false;
      state.event = payload;
    },

    getCurrentEvent: (state, { payload }) => {
      state.loading = false;
      state.currentEvent = payload;

      state.sectionone = false;
      state.sectiontwo = false;
      state.sectionthree = false;
    },

    getEventFailure: (state, { payload }) => {
      state.loading = false;
      state.event = payload;
    },

    get_creationID: (state, { payload }) => {
      state.loading = false;
      state.creationId = payload;
    },
  },
});

export const {
  getEvent,
  getEventSuccess,
  getCurrentEvent,
  getEventFailure,
  SectionOneInfo,
  getSectionOne,
  SectionTwoInfo,
  getSectionTwo,
  SectionThreeInfo,
  getSectionThree,
  SectionFourInfo,
  getSectionFour,
} = event.actions;

export const eventSelector = (state) => state.event;
export default event.reducer;

export const getsectionOneInfo = (info) => async (dispatch) => {
  try {
    dispatch(SectionOneInfo(info));
  } catch (error) {
    console.log(error);
  }
};
export const CreatesectionOne = () => async (dispatch) => {
  try {
    dispatch(getSectionOne());
  } catch (error) {
    console.log(error);
  }
};

export const getsectionTwoInfo = (info) => async (dispatch) => {
  console.log(info);
  try {
    dispatch(SectionTwoInfo(info));
  } catch (error) {
    console.log(error);
  }
};
export const CreatesectionTwo = () => async (dispatch) => {
  try {
    dispatch(getSectionTwo());
  } catch (error) {
    console.log(error);
  }
};

export const getsectionThreeInfo = (info) => async (dispatch) => {
  console.log(info);
  try {
    dispatch(SectionThreeInfo(info));
  } catch (error) {
    console.log(error);
  }
};
export const CreatesectionThree = () => async (dispatch) => {
  try {
    dispatch(getSectionThree());
  } catch (error) {
    console.log(error);
  }
};

export const getsectionFourInfo = (info) => async (dispatch) => {
  try {
    dispatch(SectionFourInfo(info));
  } catch (error) {
    console.log(error);
  }
};
export const CreatesectionFour = () => async (dispatch) => {
  try {
    dispatch(getSectionFour());
  } catch (error) {
    console.log(error);
  }
};

export const fetchAllEvent = () => async (dispatch) => {
  const key = "event";
  dispatch(getEvent());

  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + "/event");

    dispatch(getEventSuccess(data));
  } catch ({ response }) {
    dispatch(getEventFailure());
  }
};

export const fetchOneEvent = (id) => async (dispatch) => {
  dispatch(getEvent());

  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + `/event/${id}`);
    dispatch(getCurrentEvent(data));
  } catch ({ response }) {
    dispatch(getEventFailure());
  }
};

export const createEvent = (values) => async (dispatch) => {
  const key = "event";
  dispatch(getEvent());

  try {
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/event",
      values,
      config
    );
    setTimeout(() => {}, 500);
    dispatch(fetchAllEvent());
    window.location.href = "/admin/viewevents";
  } catch ({ response }) {
    dispatch(getEventFailure());
  }
};

export const deleteEvent = (id) => async (dispatch) => {
  const key = "event";
  dispatch(getEvent());

  try {
    const { data } = await axios.delete(keyUri.BACKEND_URI + `/event/${id}`);
    dispatch(fetchAllEvent());
  } catch ({ response }) {
    dispatch(getEventFailure());
  }
};

export const updateEvent = (id, values) => async (dispatch) => {
  const key = "event";
  dispatch(getEvent());

  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/event/${id}`,
      values,
      config
    );
    // window.location.reload()
    dispatch(fetchAllEvent());
    window.location.href = "/admin/viewevents";
  } catch ({ response }) {
    dispatch(getEventFailure());
  }
};
