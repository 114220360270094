import React from "react";
import Bannerm from "../2018/images/Mysore 2018/Banner.jpg";
import Bannerh from "../2018/images/Hubbli 2018/Bannerh.jpg";
import Bannerma from "../2018/images/Mangalore 2018/Bannerma.jpg";
import Bannerchk from "../2018/images/Chikkamangalore 2018/Bannerckm.webp";
import Shiv1 from "../2018/images/Shivmogga 2018/shiv1.jpg";

export default function Page2018() {
  return (
    <div>
      <h1
        class="text-center text-2xl md:text-4xl font-semibold mt-4 md:mt-10 text-red-950"
        style={{ fontFamily: "Abhaya Libre" }}
      >
        Glimpses of events - 2018
      </h1>
      <h2 class="text-center text-xl font-semibold text-red-950">
        Click on the events to see more details.
      </h2>
      <section
        class="py-10 md:py-16 bg-white overflow-hidden"
        style={{ fontFamily: "Abhaya Libre" }}
      >
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -m-9">
            <div class="w-full md:w-1/2 lg:w-1/3 p-6 md:p-10">
              <div class="group cursor-pointer">
                <div class="md:mb-6 overflow-hidden rounded-lg">
                  <a href="">
                    <img
                      class="w-full h-auto rounded-lg transform hover:scale-105 transition duration-500"
                      src={Shiv1}
                      alt=""
                    />
                  </a>
                </div>

                <h3 class="mt-2 mb-0 md:mb-4 text-2xl group-hover:text-gray-900 font-semibold text-center ">
                  Shivamogga-2018
                </h3>
              </div>
            </div>

            <div class="w-full md:w-1/2 lg:w-1/3 p-6 md:p-10">
              <div class="group cursor-pointer">
                <div class="md:mb-6 overflow-hidden rounded-lg">
                  <a href="/mys">
                    <img
                      class="w-full rounded-lg transform hover:scale-105 transition duration-500"
                      src={Bannerm}
                      alt=""
                    />
                  </a>
                </div>

                <h3 class="mt-2 mb-0 md:mb-4 text-2xl group-hover:text-gray-900 font-semibold text-center ">
                  Mysuru-June-2018
                </h3>
              </div>
            </div>

            <div class="w-full md:w-1/2 lg:w-1/3 p-6 md:p-10">
              <div class="group cursor-pointer">
                <div class="md:mb-6 overflow-hidden rounded-lg">
                  <a href="/hub">
                    <img
                      class="w-full rounded-lg transform hover:scale-105 transition duration-500"
                      src={Bannerh}
                      alt=""
                    />
                  </a>
                </div>

                <h3 class="mt-2 mb-0 md:mb-4 text-2xl group-hover:text-gray-900 font-semibold text-center ">
                  Hubballi-October-2018
                </h3>
              </div>
            </div>

            <div class="w-full md:w-1/2 lg:w-1/3 p-6 md:p-10">
              <div class="group cursor-pointer">
                <div class="md:mb-6 overflow-hidden rounded-lg">
                  <a href="/mang">
                    <img
                      class="w-full rounded-lg transform hover:scale-105 transition duration-500"
                      src={Bannerma}
                      alt=""
                    />
                  </a>
                </div>

                <h3 class="mt-2 mb-0 md:mb-4 text-2xl group-hover:text-gray-900 font-semibold text-center ">
                  Mangaluru-Nov-2018
                </h3>
              </div>
            </div>

            <div class="w-full md:w-1/2 lg:w-1/3 p-6 md:p-10">
              <div class="group cursor-pointer">
                <div class="md:mb-6 overflow-hidden rounded-lg">
                  <a href="/ckm">
                    <img
                      class="w-full rounded-lg transform hover:scale-105 transition duration-500"
                      src={Bannerchk}
                      alt=""
                    />
                  </a>
                </div>

                <h3 class="mt-2 mb-0 md:mb-4 text-2xl group-hover:text-gray-900 font-semibold text-center ">
                  Chikmagaluru-March-2018
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
