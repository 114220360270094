import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchOneBlog, blogSelector } from "../api/blog";
import { Interweave } from "interweave";
import moment from "moment";

export default function Blogview() {
  const { id } = useParams();
  const { currentBlog } = useSelector(blogSelector);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchOneBlog(id));
  }, [id]);

  return (
    <div>
      <section className="sm:py-20 py-4 bg-white">
        <div className="container mx-auto px-4">
          <div className="sm:mb-3 md:mb-10">
            <img
              className="w-96 md:w-full h-auto md:h-80 object-cover rounded-lg"
              src={currentBlog?.image}
              alt=""
            />
          </div>
          <div className="sm:mb-2 md:mb-10 max-w-2xl mx-auto text-center">
            <span className="text-xl text-red-950">
              {moment(currentBlog?.createdAt).format("DD-MMM-YYYY")}
            </span>
            <div className="mt-2">
              <h2 className="mb-3 md:mb-6 text-3xl font-bold text-red-950">
                {currentBlog?.title}
              </h2>
              <div className="text-center">
                <h3 className="hover:text-gray-600 hover:underline font-bold text-red-950">
                  {currentBlog?.author}
                </h3>
              </div>
            </div>
          </div>
          <div className="max-w-7xl text-lg mx-auto font-semibold">
            <p className="mb-6 leading-loose text-red-950">
              <Interweave content={currentBlog?.description} />
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
