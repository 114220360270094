import React, { useState, useEffect } from 'react';
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createRegister } from "../api/register";
import '../index.css'
import { AiOutlineClose } from 'react-icons/ai';
import moment from 'moment';


const RegistrationPopup = ({dataCurrent}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [minDate,setStartDate] = useState(moment().format("YYYY-MM-DD"))

  // Function to close the popup
  const closePopup = () => {
    setIsVisible(false);
  };


  useEffect(()=>{
    if(dataCurrent && dataCurrent.event_start_date)
        setStartDate(moment(dataCurrent.event_start_date).format("YYYY-MM-DD"))
  },[dataCurrent])

  useEffect(() => {
    // Show the popup after 10 seconds
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 6000);

    return () => {
      // Clear the timer when the component unmounts
      clearTimeout(timer);
    };
  }, []);


  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { id } = useParams();

  const onSubmit = (data) => {
    const sendData = {
      ...data,
      event_place: dataCurrent._id,
      date: new Date(),
    };

    dispatch(createRegister(sendData));
  };

  return (
    <div className={`registration-popup ${isVisible ? 'visible' : 'hidden'}`}>
      <div className="popup-content">
      <button className='float-right' onClick={closePopup}><AiOutlineClose/></button>
      <div className="p-6">
                 
                    <h3 class="mb-3 text-xl font-semibold text-gray-900">
                      Register Now
                      {/* {dataCurrent?.venue_name} - {dataCurrent?.event_city} */}
                    </h3>
                    <form
                      form="form"
                      onSubmit={handleSubmit(onSubmit)}
                      class="space-y-6"
                      action="#"
                    >
                      <div>
                        <input
                          {...register("name")}
                          type="name"
                          name="name"
                          id="name"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                          placeholder="Name"
                          required
                        />
                      </div>
                      <div>
                        <input
                          {...register("phone")}
                          type="number"
                          name="phone"
                          id="phone"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                          placeholder="Phone number"
                          required
                        />
                      </div>
                      <div>
                        <input
                          {...register("email")}
                          type="email"
                          name="email"
                          id="email"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                          placeholder="Email"
                          required
                        />
                      </div>

                      {/* <div>
                        <input
                          {...register("date")}
                          type="date"
                          name="date"
                          id="date"
                          placeholder=""
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                          required
                          min = {minDate}
                        />
                      </div> */}

                      <button
                        type="submit"
                        class="w-full text-white bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
        {/* Add your registration form fields and submit button */}
        
      </div>
    </div>
  );
};

export default RegistrationPopup;




