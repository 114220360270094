import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllBlog, blogSelector } from "../api/blog";
import { Link } from "react-router-dom";
import slugify from "slugify";
import moment from "moment";

export default function Blogs() {
  const { blog } = useSelector(blogSelector);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchAllBlog());
  }, []);

  return (
    <div>
      <section class="py-3 md:py-10 overflow-hidden">
        <div class="container mx-auto px-4">
          <div class="md:max-w-lg mx-auto text-center md:mb-16">
            <h2 class="md:mb-4 font-heading font-semibold text-red-950 text-3xl sm:text-5xl">
              Latest from our blog
            </h2>
          </div>

          <div className="hidden md:block">
            <div
              class=" max-w-screen-xl mx-auto grid grid-cols-3 gap-7  md:mt-20 "
              style={{ fontFamily: "Abhaya Libre" }}
            >
              {blog.map((item, i) => {
                const slugTitle = slugify(item?.title, {
                  remove: /[?]/g,
                  replacement: "_",
                });
                return (
                  <Link
                    to={`/blogview/${slugTitle}`}
                    class=" -mx-3 mb-8 lg:mb-6"
                  >
                    <a class="group" href="#">
                      <div class=" mb-5 overflow-hidden rounded-xl">
                        <img class="w-96 h-auto" src={item?.image} alt="" />
                      </div>
                      <p class=" mb-2 font-heading font-bold text-2xl text-red-950 ">
                        {" "}
                        {item?.title}
                      </p>

                      <h2 class="font-heading font-medium text-xl  text-gray-700 tracking-px">
                        {" "}
                        {item?.author}{" "}
                        <span className="">
                          {moment(item?.createdAt).format("DD-MMM-YYYY")}
                        </span>
                      </h2>
                    </a>
                  </Link>
                );
              })}
            </div>
          </div>
          {/* ---------------------------------------------------------------------------------------- */}
          <div className="block md:hidden">
            <div
              class=" max-w-screen-xl mx-auto "
              style={{ fontFamily: "Lato" }}
            >
              {blog.map((item, i) => {
                const slugTitle = slugify(item?.title, {
                  remove: /[?]/g,
                  replacement: "_",
                });
                return (
                  <Link
                    to={`/blogview/${slugTitle}`}
                    class=" -mx-3 mb-1 lg:mb-6"
                  >
                    <a class="group" href="#">
                      <div class="mb-2 overflow-hidden rounded-xl">
                        <img class="w-96 h-auto" src={item?.image} alt="" />
                      </div>
                      <p class=" mb-2 font-heading font-medium text-xl text-red-950 ">
                        {" "}
                        {item?.title}
                      </p>

                      <h2 class="font-heading font-medium text-xs  text-gray-700 tracking-px">
                        {" "}
                        {item?.author}{" "}
                        <span className="">
                          {moment(item?.createdAt).format("DD-MMM-YYYY")}
                        </span>
                      </h2>
                    </a>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
