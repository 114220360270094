import React, { useEffect } from 'react'
import { eventSelector, fetchAllEvent } from '../api/events'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

export default function Show() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { event } = useSelector(eventSelector);

    useEffect(()=>{
        dispatch(fetchAllEvent())
    },[])
    useEffect(() => {
        // Check if event[0] and event[0]._id exist before navigating
        if (event[0] && event[0]._id) {
          navigate(`/showpage/${event[0]._id}`);
        }
      }, [event]);
  return (
    <div>
        {/* <a href = {`/showpage/${event[0]?._id}`}>https://www.thejewelleryshow.in/#/show1</a> */}
    </div>
  )
}
