import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";

export const initialState = {
  loading: false,
  hasErrors: false,
  blog: [],
  currentBlog: null,
};

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    getBlog: (state) => {
      state.loading = true;
    },

    getBlogSuccess: (state, { payload }) => {
      state.loading = false;
      state.blog = payload;
    },

    getCurrentBlog: (state, { payload }) => {
      state.loading = false;
      state.currentBlog = payload;
    },
    getBlogFailure: (state, { payload }) => {
      state.loading = false;
      state.blog = payload;
    },
  },
});

export const { getBlog, getBlogSuccess, getCurrentBlog, getBlogFailure } =
  blogSlice.actions;

export const blogSelector = (state) => state.blog;
export default blogSlice.reducer;

export const fetchAllBlog = () => async (dispatch) => {
  const key = "blog";
  dispatch(getBlog());

  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + "/blog");

    dispatch(getBlogSuccess(data));
  } catch ({ response }) {
    dispatch(getBlogFailure());
  }
};

export const fetchOneBlog = (id) => async (dispatch) => {
  dispatch(getBlog());

  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + `/admin-blog/${id}`);
    dispatch(getCurrentBlog(data));
  } catch ({ response }) {
    dispatch(getBlogFailure());
  }
};

export const createBlog = (values) => async (dispatch) => {
  const key = "blog";
  dispatch(getBlog());

  try {
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/blog",
      values,
      config
    );
    setTimeout(() => {}, 500);
    dispatch(fetchAllBlog());
  } catch ({ response }) {
    dispatch(getBlogFailure());
  }
};

export const deleteBlog = (id) => async (dispatch) => {
  const key = "blog";
  dispatch(getBlog());

  try {
    const { data } = await axios.delete(keyUri.BACKEND_URI + `/blog/${id}`);
    dispatch(fetchAllBlog());
  } catch ({ response }) {
    dispatch(getBlogFailure());
  }
};

export const updateBlog = (id, values) => async (dispatch) => {
  const key = "blog";
  dispatch(getBlog());

  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/blog/${id}`,
      values,
      config
    );
    window.location.reload();
    dispatch(fetchAllBlog());
  } catch ({ response }) {
    dispatch(getBlogFailure());
  }
};
