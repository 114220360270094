import React, { useEffect } from "react";
import Section1 from "../showpage/section1";
import Section2 from "../showpage/section2";
import Section3 from "../showpage/section3";
import Section4 from "../showpage/section4";
import Section5 from "../showpage/section5";

import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchOneEvent, eventSelector } from "../../api/events";

export default function Index() {
  //step 1
  const { id } = useParams();

  //step 2
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOneEvent(id));
  }, [id]);

  //step 3
  const { currentEvent } = useSelector(eventSelector);

  return (
    <div>
      <Section1 data={currentEvent} />
      <Section2 data={currentEvent} />
      <Section4 data={currentEvent} />
      <Section5 data={currentEvent} />
      <Section3 data={currentEvent} />

    </div>
  );
}
