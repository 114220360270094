import React from "react";
import Bannerd from "../2019/images/Davangere 2019/bannerd.jpg";
import Bannerm from "../2019/images/Mysore 2019/bannerm.jpg";
import Bannerh from "../2019/images/Hubli 2019/bannerh.jpg";
import Bannerb from "../2019/images/Ballari 2019/bannerb.jpg";
import Bannert from "../2019/images/Tumkur 2019/bannert.jpg";

export default function Page2019() {
  return (
    <div>
      <h1
        class="text-center text-2xl md:text-4xl font-semibold mt-4 md:mt-10 text-red-950"
        style={{ fontFamily: "Abhaya Libre" }}
      >
        Glimpses of events - 2019
      </h1>
      <h2 class="text-center text-xl font-semibold text-red-950">
        Click on the events to see more details.
      </h2>
      <section
        class="py-10 md:py-16 bg-white overflow-hidden"
        style={{fontFamily:'Abhaya Libre'}}
      >
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -m-9">
            <div class="w-full md:w-1/2 lg:w-1/3 p-6 md:p-10">
              <div class="group cursor-pointer">
                <div class="md:mb-6 overflow-hidden rounded-lg">
                  <a href="/ballary">
                    <img
                      class="w-full h-auto rounded-lg transform hover:scale-105 transition duration-500"
                      src={Bannerb}
                      alt=""
                    />
                  </a>
                </div>

                <h3 class="mt-2 mb-0 md:mb-4 text-2xl group-hover:text-gray-900 font-semibold text-center ">
                  Ballari-June-2019
                </h3>
              </div>
            </div>

            <div class="w-full md:w-1/2 lg:w-1/3 p-6 md:p-10">
              <div class="group cursor-pointer">
                <div class="md:mb-6 overflow-hidden rounded-lg">
                  <a href="/dvg">
                    <img
                      class="w-full h-auto rounded-lg transform hover:scale-105 transition duration-500"
                      src={Bannerd}
                      alt=""
                    />
                  </a>
                </div>

                <h3 class="mt-2 mb-0 md:mb-4 text-2xl group-hover:text-gray-900 font-semibold text-center ">
                  Davanagere-Aug-2019
                </h3>
              </div>
            </div>

            <div class="w-full md:w-1/2 lg:w-1/3 p-6 md:p-10">
              <div class="group cursor-pointer">
                <div class="md:mb-6 overflow-hidden rounded-lg">
                  <a href="/mys1">
                    <img
                      class="w-full h-auto rounded-lg transform hover:scale-105 transition duration-500"
                      src={Bannerm}
                      alt=""
                    />
                  </a>
                </div>

                <h3 class="mt-2 mb-0 md:mb-4 text-2xl group-hover:text-gray-900 font-semibold text-center ">
                  Mysuru-March-2019
                </h3>
              </div>
            </div>

            <div class="w-full md:w-1/2 lg:w-1/3 p-6 md:p-10">
              <div class="group cursor-pointer">
                <div class="md:mb-6 overflow-hidden rounded-lg">
                  <a href="/tumk1">
                    <img
                      class="w-full h-auto rounded-lg transform hover:scale-105 transition duration-500"
                      src={Bannert}
                      alt=""
                    />
                  </a>
                </div>

                <h3 class="mt-2 mb-0 md:mb-4 text-2xl group-hover:text-gray-900 font-semibold text-center ">
                  Tumakuru-Jan-2019
                </h3>
              </div>
            </div>

            <div class="w-full md:w-1/2 lg:w-1/3 p-6 md:p-10">
              <div class="group cursor-pointer">
                <div class="md:mb-6 overflow-hidden rounded-lg">
                  <a href="/hubbli1">
                    <img
                      class="w-full h-auto rounded-lg transform hover:scale-105 transition duration-500"
                      src={Bannerh}
                      alt=""
                    />
                  </a>
                </div>

                <h3 class="mt-2 mb-0 md:mb-4 text-2xl group-hover:text-gray-900 font-semibold text-center ">
                  Hubbali-Oct-2019
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
