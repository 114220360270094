import React from "react";

export default function Section1({ data }) {
  return (
    <div>
      <section
        class="relative py-4 md:py-0 md:pb-5 "
        style={{ fontFamily: "Abhaya Libre" }}
      >
        <img
          class="hidden lg:block absolute top-0 left-0 mt-24"
          src="zeus-assets/icons/dots/blue-dot-left-bars.svg"
          alt=""
        />
        <img
          class="hidden lg:block absolute top-0 right-0 mt-40"
          src="zeus-assets/icons/dots/yellow-dot-right-shield.svg"
          alt=""
        />
        <div class="container px-4 mx-auto">
          <div class="h-112 ">
            <img
              class="md:p-0 w-full  object-cover object-top rounded-lg"
              // style={{ height: "780px" }}
              src={data?.event_image}
              alt=""
            />
          </div>
        </div>
      </section>
    </div>
  );
}
