import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEvent, eventSelector } from "../../api/events";
import Section1 from "./section1";
import Section2 from "./section2";
import moment from "moment";

export default function Index() {
  const { event } = useSelector(eventSelector);
  const dispatch = useDispatch();
  const [currentEvent, setCurrEvent] = useState([]);

  useEffect(() => {
    for (let i = 0; i < event.length; i++) {
      if (event[i]?.event_start_date > moment().format()) {
        setCurrEvent([event[i]]);
        break;
      }
    }
  }, [event]);

  useEffect(() => {
    dispatch(fetchAllEvent());
  }, []);

  return (
    <div>
      {event.length < 1 || currentEvent?.length < 1 ? (
        <p className=" text-center text-red-800 text-3xl font-bold h-64 mt-32">
          We will be back soon
        </p>
      ) : (
        <>
          <Section1 />
          <Section2 />
        </>
      )}
    </div>
  );
}
