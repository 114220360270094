import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllTestimonial,
  testimonialSelector,
} from "../../api/testimonials";
import { Interweave } from "interweave";
import Slider from "react-slick";

export default function Section5() {
  const { testimonial } = useSelector(testimonialSelector);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchAllTestimonial());
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 1000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <section
        class="hidden md:block relative py-10 lg:py-16 overflow-hidden bg-amber-50"
        style={{ fontFamily: "Abhaya Libre" }}
      >
        <div class="relative container px-4 mx-auto">
          <div class="max-w-lg lg:max-w-7xl mx-auto">
            <div class="flex flex-wrap -mx-4 mb-18 items-center">
              <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                <div class="max-w-md xl:max-w-2xl">
                  <h1 class="font-heading text-4xl md:text-5xl font-bold text-amber-950">
                    <span>Our</span>
                    <span> Happy</span>
                    <span>
                      {" "}
                      Customers <br /> says about us
                    </span>
                  </h1>
                </div>
              </div>
            </div>

            <Slider {...settings}>
              {testimonial?.map((item, i) => {
                return (
                  <div class=" flex-wrap -mx-4 items-center">
                    <div class="w-full lg:w-full px-4 mb-8 sm:mt-8 lg:mb-0 flex justify-between">
                      <div class="lg:max-w-md xl:max-w-lg ">
                      <iframe
                          width="460"
                          height="296"
                          src={item?.video_link}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                        {/* <img
                          class="block w-full rounded-lg"
                          src={item?.video_link}
                          alt=""
                        /> */}
                      </div>

                      <div class="w-full lg:w-full px-4">
                        <div class="max-w-3xl">
                          <p class="ml-5 text-xl md:text-2xl font-semibold text-gray-900 mb-3">
                            <Interweave content={item?.description} />
                          </p>
                          <span class="ml-5 block text-gray-900 font-semibold mb-1">
                            {item?.author}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>

      {/* -----------------------------------------------------mobile---------------------------------------------- */}

      <section
        class="block md:hidden relative py-10 lg:py-16 overflow-hidden bg-amber-50"
        style={{ fontFamily: "Abhaya Libre" }}
      >
        <div class="relative container px-4 mx-auto">
          <div class="max-w-lg lg:max-w-7xl mx-auto">
            <div class="flex flex-wrap -mx-4 mb-18 items-center">
              <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                <div class="max-w-md xl:max-w-2xl">
                  <h1 class="font-heading text-4xl md:text-5xl font-bold text-amber-950">
                    <span>Our</span>
                    <span> Happy</span>
                    <span>
                      {" "}
                      Clients <br /> says about us
                    </span>
                  </h1>
                </div>
              </div>
            </div>

            <Slider {...settings}>
              {testimonial.map((item, i) => {
                return (
                  <div class="p-2 items-center">
                    <div class="max-w-lg ">
                    <iframe
                        width="320"
                        height="200"
                        src={item?.video_link}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>

                    <div class=" mt-3 w-full px-4">
                      <div class="max-w-3xl">
                        <p class="text-xl md:text-2xl font-semibold text-gray-900 mb-5">
                          <Interweave content={item?.description} />
                        </p>
                        <span class="block text-gray-900 font-semibold mb-1">
                          {item?.author}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
}
