import { configureStore } from "@reduxjs/toolkit";
import Gallery from "../api/gallery";
import Blog from "../api/blog";
import Events from "../api/events";
import Banner from "../api/banner";
import Contact from "../api/contact";
import Register from "../api/register";
import Logo from "../api/logo";
import Testimonial from "../api/testimonials";
import Mobilebanner from "../api/mobilebanner";

export default configureStore({
  reducer: {
    gallery: Gallery,
    blog: Blog,
    event: Events,
    banner: Banner,
    contactEnquiry: Contact,
    logo: Logo,
    testimonial: Testimonial,
    register: Register,
    mobbanner: Mobilebanner,
  },
});
