import React from "react";
import moment from "moment";

export default function Section4({ data }) {

  return (
    <div>
      <section>
        <div
          class=" mt-5 container sm:pb-10 px-4 mx-auto py-4 md:py-10 bg-amber-50"
          style={{ fontFamily: "Abhaya Libre" }}
        >
          <div class="mb-6 lg:mb-14 flex flex-wrap items-end justify-between">
            <div class="mb-2 md:mb-0 ">
              <h2 class=" text-3xl md:text-5xl font-semibold text-red-950">
                Glimpses of {data?.event_city} -{" "}
                {moment(data?.date).format("YYYY")}
               
              </h2>
            </div>
            <a
              class="inline-block ml-auto md:ml-0 py-2 px-8 text-white font-medium leading-normal text-sm bg-amber-950  rounded transition duration-200"
              style={{ fontFamily: "Arimo" }}
              href={data?.link}
            >
              View all images
            </a>
          </div>
          <div class="flex flex-wrap -mx-3">
            <div class="w-full md:w-1/2 p-3">
              <img
                class="w-full h-60 md:h-72 object-none  rounded-lg"
                src={data?.glimpses_image}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 p-3">
              <img
                class="w-full h-60 md:h-72 object-none  rounded-lg"
                src={data?.glimpses_image1}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-60 md:h-72 object-cover rounded-lg"
                src={data?.glimpses_image2}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-60 md:h-72 object-cover rounded-lg object-top"
                src={data?.glimpses_image3}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-60 md:h-72 object-cover rounded-lg"
                src={data?.glimpses_image4}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 p-3">
              <img
                class="w-full h-60 md:h-72 object-none rounded-lg"
                src={data?.glimpses_image5}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 p-3">
              <img
                class="w-full h-60 md:h-72 object-none rounded-lg"
                src={data?.glimpses_image6}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
